import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavItem,
    MDBNavLink,
    MDBIcon,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBBadge
} from 'mdbreact';

import { AppConsumer } from "../uplift/AppContext";
import { getHandler } from "../uplift/actions";

class TopNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
        };
        this.onClick = this.onClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleToggleClickA = this.handleToggleClickA.bind(this);
    }

    onClick() {
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    handleToggleClickA() {
        this.props.onSideNavToggleClick();
    }

    render() {
        const navStyle = {
            paddingLeft:
                this.props.toggle ? '16px' : "240px",
            transition: 'padding-left .3s'
        };
        return (
            <Router>
                  <AppConsumer>
      { (context) => (
                <MDBNavbar className="flexible-MDBNavbar" light expand="md" scrolling fixed="top" style={{ zIndex: 3 }}>
                    <div
                        onClick={this.handleToggleClickA}
                        key="sideNavToggleA"
                        style={{
                            lineHeight: "32px",
                            marginleft: "1em",
                            verticalAlign: "middle",
                            cursor: 'pointer'
                        }}
                    >
                        <MDBIcon icon="bars" color="white" size="lg" />
                    </div>

                    <MDBNavbarBrand href="/" style={navStyle}>
                        <strong>{this.props.routeName}</strong>
                    </MDBNavbarBrand>
                    <MDBNavbarNav expand="sm" right style={{ flexDirection: 'row' }}>
                        <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                            {context.userInfo && <MDBBadge color="red" className="mr-2">1</MDBBadge>}
                                <MDBIcon icon="bell" /> <span className="d-none d-md-inline">Notifications</span>
                            </MDBDropdownToggle>
                            {context.userInfo && <MDBDropdownMenu right style={{ minWidth: '400px' }}>
                                {/*<MDBDropdownItem href="#!">
                                    <MDBIcon icon="money-bill-alt" className="mr-2" />
                                    New order received
                                    <span className="float-right"><MDBIcon icon="clock" /> 13 min</span>
                                    </MDBDropdownItem>*/}
                                {context.userInfo && <MDBDropdownItem href="#!">
                                    <MDBIcon key="1" icon="money-bill-alt" className="mr-2" />
                                    Welcome to Sesame Open, please fill out below onboarding form to start your free demo process.
                                    {/*<span className="float-right"><MDBIcon icon="clock" /> </span>*/}
                                </MDBDropdownItem>}
                                {/*<MDBDropdownItem href="#!">
                                    <MDBIcon icon="chart-line" className="mr-2" />
                                    Your campaign is about to end
                                    <span className="float-right"><MDBIcon icon="clock" /> 53 min</span>
                                </MDBDropdownItem>*/}
                            </MDBDropdownMenu>}
                        </MDBDropdown>
                        <MDBNavItem>
                            <a className="nav-link" href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}?Subject=Enquries`}  target="_top">
                                <MDBIcon icon="envelope" />
                                    <span className="d-none d-md-inline ml-1">Contact</span>
                            </a>
                        </MDBNavItem>
                        {/*<MDBNavItem>
                            <MDBNavLink to="#"><MDBIcon icon="comments" />
                                <span className="d-none d-md-inline ml-1">Support</span>
                            </MDBNavLink>
                        </MDBNavItem>*/}
                        {!context.userInfo && <MDBNavItem>
                            <MDBNavLink to="#" onClick={()=>getHandler()([{type:"REQUIRE_LOGIN"}])}><MDBIcon icon="sign-in-alt" />
                                <span className="d-none d-md-inline ml-1">Login</span>
                            </MDBNavLink>
                        </MDBNavItem>}
                        {context.userInfo && <MDBDropdown>
                        <MDBDropdownToggle nav caret>
                                <MDBIcon icon="user" /> <span className="d-none d-md-inline">{context.userInfo?context.userInfo.email:"Profile"}</span>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu right style={{ minWidth: '200px' }}>
                                <MDBDropdownItem href="#!" onClick={()=>getHandler()([{type:"LOGOUT"}])}>
                                    Log Out
                                </MDBDropdownItem>
                                <MDBDropdownItem >
                                    <a href={process.env.REACT_APP_KEYCLOAK_ACCOUNT} target="_blank">My Account</a>
                                </MDBDropdownItem>
                              
                            </MDBDropdownMenu>
                        </MDBDropdown>}
                    </MDBNavbarNav>
                </MDBNavbar>
                            
            )}
                 </AppConsumer>
            </Router>
        );
    }
}

export default TopNavigation;