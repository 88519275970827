import React from 'react';
import Keycloak from 'keycloak-js';
import { history } from './history';
import axios from 'axios';
import update, { extend }from 'immutability-helper';

import {  Store } from '../AppContext';

import { toast } from 'mdbreact';

import ct from "../utilities/ct";

import {
  setLanguage
} from 'web-translate'; //'../utilities/web-translate/src/index'; //

import * as Sentry from '@sentry/browser';

require("../utilities/ployfill")


extend('$upsert_array', function({predicate, value}, ms) {
    const irIndex = ms.findIndex(predicate);
    console.log(ms, irIndex, value)
    if(irIndex>-1) {
        ms[irIndex] = value
    }else{
        ms.push(value)
    }
    console.log("after", ms)
    return ms;
});

const c = ct(process.env.REACT_APP_ENCRYPTION_KEY, process.env.REACT_APP_ENCRYPTION_IV);

let _handler;
export function init(component){
  _handler = handleActions(component);
  console.log("handler inited", component, _handler);
}
export function getHandler(){ return _handler;}

function reload(ms, component){
  if(component)
  setTimeout(()=>component.setState({refresh:!component.state.refresh}),ms);
  else{
  const target = window.location.pathname +  window.location.search;
  history.replace({ pathname: process.env.REACT_APP_LOADING_PATH||"/" });
  setTimeout(() => {
     history.replace(target);
    }, ms);
  }
}
async function _loading(newState, component, keycloak){
  console.log("push history", process.env.REACT_APP_LOADING_PATH||"/")
  reload(500);
  // component.setState({credential:keycloak})
  //console.log(await keycloak.loadUserProfile())
  keycloak.loadUserProfile().then(userInfo=>{ 
    newState.userInfo = userInfo;
    component.setState(newState);
    console.log(newState);
    });
}

export default function handleActions(component){
  return async function(actions, parentRef, thisRef) {
    if (process.env.ACTION_LIMIT && actions.length > process.env.ACTION_LIMIT || actions.length >100)
    {
      console.error("too many actions!")
      Sentry.captureException("too many actions!")
      return;
    }
    try {
      const basePath = component.state.basePath!==""?component.state.basePath:"";
      for (const a of actions)
        await act(a).catch(e=>{console.error(a,e); throw e}); //stop the flow now


      // actions.forEach( async (a,i) => // pareelel
      async function act(a)
      {
        console.log("ACTIONS===========: ", a.type, a.props);
        switch(a.type){
          case "SET_NAV":
            if(a.props.type == "SIDE_NAV")
              component.setState({side_nav: a.props.props });
            if(a.props.type == "HORIZON_NAV")
              component.setState({horizon_nav: a.props.props });
            break;
          case "SET_PAGE":
            let tempPages = component.state.pages;
            //const path = a.props.path;
            tempPages[basePath+a.props.path] = a.props;
            // nelow is open page
            component.setState({
              pages: tempPages,
            })
            break;
          case "GOTO":
              history.push({ pathname: a.props.path });
              break;
          case "CLIENT_REQUIRE_REGISTRATION":
          case "REQUIRE_LOGIN":
            console.log("REQUIRE_REGISTRATION or REQUIRE_LOGIN")
            /*await new Promise((res, rej) => {
              if (a.type === "CLIENT_REQUIRE_REGISTRATION" && component.state.credential){
                return res()
              }*/

            const login = async ()=>{
              console.log("Login now")
              const keycloak = Keycloak(a.props || 
              {...JSON.parse(process.env.REACT_APP_KEYCLOAK),
                action:a.type==="REQUIRE_LOGIN"?"login":"register"
              });
            //keycloak.logout();
            //keycloak.init({onLoad: 'login-required'})
            try{
            const authenticated = await keycloak.init({onLoad: 'login-required', promiseType:"native"});
            //.then(authenticated => 
              {
                if (authenticated){
                  let newState = Object.assign({}, component.state);
                  newState.credential = keycloak;
                  console.log("set credential...");
                  component.setState(newState);
                  
                  _loading(newState, component, keycloak);
                  
                } else {
                  console.log("not authenticated");
                  component.setState({credential: null, userInfo: null});
                }
              }
            }catch(e){  
                console.log(e);
                Sentry.captureException(e);
              };
            }
            if (a.type==="CLIENT_REQUIRE_REGISTRATION")parentRef.openModal("Sign up or Login is required", 
            "Proceed to Sign up or Login?",
            login, ()=>{console.log("signup/login canceled")});
            else await login();
            //});
            break;
            case "CHECK_LOGIN":
                console.log(a.props || JSON.parse(process.env.REACT_APP_KEYCLOAK))
                const keycloak = Keycloak(a.props || JSON.parse(process.env.REACT_APP_KEYCLOAK));
                try{
                  const authenticated = await keycloak.init({
                  onLoad: 'check-sso',
                  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
                  promiseType: 'native',
                })
               
                  if (authenticated){
                    console.log("authenticated");
                    let newState = Object.assign({}, component.state);
                    newState.credential = keycloak; // component.setState({credential:keycloak})
                    component.setState(newState);

                    _loading(newState, component, keycloak);
                  
     
                  } else {
                    console.log("not authenticated");
                    component.setState({credential: null, userInfo: null});
    
                  }
                
                }catch(e){
                  console.log("unable to authenticate", e);
                }
              break;
          case "OPEN_PAGE":
            console.log("component===>", component);
          
            if(!component.state.pages[basePath+a.props.path]|| !component.state.pages[basePath+a.props.path].components){
              console.log("triggering",{type:"GET", props:{path:a.props.path}})
              await component.state.handleActions([{type:"GET", props:{path:a.props.path}}]);
            }else{
              console.log(basePath,a.props.path)
              component.setState({routePath: basePath+a.props.path});
              history.push(basePath+a.props.path);
            }
            break;
          case "LOGOUT":
            console.log("logout...")
            await component.state.credential.logout();
            break;
          case "GET":
            await new Promise((res, rej) => {
              const url = `${window.location.protocol}//${process.env.SERVER?process.env.SERVER:window.location.hostname}${process.env.SERVER?"":`:${process.env.REACT_APP_API_PORT}`}${a.props.path}`
              console.log("calling", url);
              component.setState({
                showLoader: true,
              })
              axios.get(url,
                {
                  headers: {
                   'Authorization': `Bearer ${component.state.credential?component.state.credential.token:''}`
                   ,'X-PROJECT-INFRA-ID': process.env["X-PROJECT-INFRA-ID"]
                 },
                 transformResponse: undefined
                })
                  .then(async function (response) {
                    // handle success
                    //console.log("succesfully get:", response);
                    //console.log(c.decrypt(response.data));
                    const d = c.decrypt(response.data);
                    console.log("decrypted",d);
                    await component.state.handleActions(JSON.parse(d));
                    res();
                  })
                  .catch(function (error) {
                    // handle error
                    console.log(error);
                    Sentry.captureException(error);
                    rej(error);
                    component.setState({
                      showLoader: false,
                    })
                  })
                  .then(function () {
                    // always executed
                    component.setState({
                      showLoader: false,
                    })
                  });
              });
              break;
            case "HTTP4":
              await new Promise((res, rej) => {
                const { loginRequired, method, endpoint, data, noSpinner, onStart, onComplete, contentType } = a.props;
                if (loginRequired && !component.getState().credential) { 
                  console.log("not login, skip");
                  return;
                }
                const url = !endpoint?`${process.env.REACT_APP_SERVER_HOSTONLY}${a.props.path}`:endpoint;
                const requireEnc = (!endpoint && process.env.REACT_APP_ENCRYPTION_KEY && process.env.REACT_APP_ENCRYPTION_IV)?true:false;
                {
                  console.log("creadential", component.getState())
                const headers = {
                  'Authorization': `Bearer ${component.getState().credential?component.getState().credential.token:''}`
                  ,'X-PROJECT-INFRA-ID': process.env.REACT_APP_X_PROJECT_INFRA_ID,
                  'Content-Type':contentType?contentType:(requireEnc)?'text/plain':"application/json",
                }
                //Alert.alert(`calling ${url}`);
                if (onStart && typeof onStart === 'function'){ onStart()};
                console.log("a.props", JSON.stringify( a.props));
                if(!noSpinner){
                  parentRef.setState({showLoader:true});
                }
                let req = { data, method, url,
                  transformResponse: (requireEnc)?[function(data) {
                    return JSON.parse(c.decrypt(data)); // "{ name: "apple", type: "fruit"}"
                  }]: axios.defaults.transformResponse,
                  transformRequest:(requireEnc)?
                  [(data,headers)=>{if(data) {const ret = c.encrypt(JSON.stringify(data)); console.log("transformed body",data,ret); return ret;}else return null;}]:
                  axios.defaults.transformRequest,  headers,  };
                console.log(JSON.stringify( req.data));  
                try {
                 axios(req).then(async function (response) {
                      // handle success
                      console.log("succesfully get1:", response?(response.data):null);
                      return response;
                      //Alert.alert(`get ${JSON.stringify(response)}`);
                    })
                    .catch(function (error) {
                      // handle error
                      console.log(error)
                      Sentry.captureException(error)
                      //error = JSON.parse(c.decrypt(error));
                      console.log("res error",JSON.stringify(error));
                      if(error.response && error.response.errorId)
                      {
                        _handler([{type:"ALERT",props:{title:"An error occoured, please retry...",message:error.response.errorId}}]);
                      }
                      else if (error.request &&  error.request._response){
                        _handler([{type:"ALERT",props:{title:"An error occoured, please retry...",message:c.decrypt(error.request._response)}}]);
                      }
                      rej(error);
                      if (onComplete && typeof onComplete === 'function'){ onComplete()};
                      if(!noSpinner){
                        parentRef.setState({showLoader:false});
                      }
                    })
                    .then(async function (response) {
                      // always executed
                      if (response) {
                        await _handler(response.data, parentRef, thisRef);
                      }
                      
                      if (onComplete && typeof onComplete === 'function'){ onComplete()};
                      res();
                      if(!noSpinner){
                        parentRef.setState({showLoader:false});
                      }
                    });
                  }catch(e){
                    console.error("client-side error",e);
                    Sentry.captureException(e)
                    if (onComplete && typeof onComplete === 'function'){ onComplete()};
                    // monoitoring for client-side bug
                    if(!noSpinner){
                      parentRef.setState({showLoader:false});
                    }
                  }
                }
                });
                break;
          case "SET_LOCAL_STATE_V3":
            thisRef.setState(update(thisRef.state, JSON.parse(JSON.stringify(a.props.state))));
            break;
          case "TOAST":
              toast[a.props.type||"success"](a.props.message, {
                ...a.props.options, closeButton: a.props.closeButton||false
              });
              break;
          // only in-memory   
          case "WEB_FORMDATA":
              const { requireLogin, private_file, opts, onComplete, onStart, onProgress, onError} = a.props;
                
                if (requireLogin) await _handler([
                  {type:"REQUIRE_LOGIN", props: JSON.parse(process.env.REACT_APP_KEYCLOAK)}
                ], parentRef, thisRef);
                const options = Object.assign({
                  method: 'POST',
                  headers: {
                    //'content-type': metadata.mimeType, // server requires a content-type header
                    'Authorization': `Bearer ${component.getState().credential?component.getState().credential.token:''}`,
                    'PRIVATE-FILE': private_file?true:false
                  }
                }, opts)
                const data = new FormData();
                //data.append('name', this.state.selectedImage.fileName); // you can append anyone.
                data.append(opts.file.name, opts.file);
                if (onStart) onStart(options);
                parentRef.setState({showLoader:true});
                axios.post(opts.url, data, options).then(res => {
                    parentRef.setState({showLoader:false});
                    console.log(res)
                    if (onComplete) onComplete(res);
                      return;
                  }).catch(e=>{
                    parentRef.setState({showLoader:false});
                    console.log(e);
                    Sentry.captureException(e)
                    if (onError) onError(e); 
                  });
              break;
          case "SET_LANGUAGE":
             setLanguage(a.props.lan);
             component.setState({lan: a.props.lan});
             reload(100, component);
             break;
          case "HISTORY":
              history[a.props.method](a.props.path);
              break;
          case "MERGE_PAGE":
            {const index = component.state.pages[basePath+a.props.path]
                .components.findIndex(e=>e.id==a.props.id);
            let newState;
            if(a.props.replace)
            newState = update(component.state, {
                pages: {[basePath+a.props.path]: {components: {[index]: {
                props:{$set: a.props.properties}
                    }}}}
            });
            else
              newState = update(component.state, {
                pages: {[basePath+a.props.path]: {components: {[index]: {
                        props:{ ...a.props.properties}
                      }}}}
              });
            component.setState(newState);
            break;
          }
          case "SPLICE_COMPONENT":
            {
              const index = component.state.pages[basePath+a.props.path]
                .components.findIndex(e=>e.id==a.props.properties.id);
            let newState;
            if(index==-1){
              const after_index = component.state.pages[basePath+a.props.path]
                 .components.findIndex(e=>e.id==a.props.afterId);
              if(after_index>=0){
                console.log("insert...");
                newState = update(component.state, {
                  pages: {[basePath+a.props.path]: {components: {
                    $splice: [[after_index+1, 0, a.props.properties]]
                    }}}
                  });
                }
            }else{
              console.log("replacing...");
              newState = update(component.state, {
                pages: {[basePath+a.props.path]: {components: {[index]: {
                        props:{$set: a.props.properties.props}
                      }}}}
                });
            }
            component.setState(newState);
            break;
          }
          // ##########################################
          // V3 actions
          // ##########################################
          case "SET_VERSION":
            // .app.{id}:
            //Store.getState().version = a.props.version;
            component.setState({version:a.props.version});
            console.log("VERSION",component.state.version);
            break;
     
          
          default:
            break;
        }
      }

    } catch (err) {
      console.log("ACTIONS FAILED", err);
      Sentry.captureException(err);
    }
  }
}
