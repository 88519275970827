import React from 'react';
import { 
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBBtn,
} from 'mdbreact';
import LinkCard from '../LinkCard';

const Panels = () => {
  return (
    <div id="panels">
      <MDBContainer fluid>

      <h5 className="my-5 dark-grey-text font-weight-bold">Background variants</h5>

        <MDBRow>
          <MDBCol md="6" className="mb-4">
            <MDBCard color="indigo" text="white" className="text-center mb-4">
              <MDBCardBody>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
              </MDBCardBody>
            </MDBCard>

            <MDBCard color="pink lighten-2" text="white" className="text-center mb-4">
              <MDBCardBody>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
              </MDBCardBody>
            </MDBCard>

            <MDBCard color="info-color" text="white" className="text-center mb-4">
              <MDBCardBody>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="text-center mb-4 dark-grey">
              <MDBCardBody>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol md="6">
            <MDBCard color="red lighten-1" text="white" className="text-center mb-4">
              <MDBCardBody>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
              </MDBCardBody>
            </MDBCard>
            <MDBCard color="success-color" text="white" className="text-center mb-4">
              <MDBCardBody>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
              </MDBCardBody>
            </MDBCard>
            <MDBCard color="mdb-color lighten-2" text="white" className="text-center mb-4">
              <MDBCardBody>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
              </MDBCardBody>
            </MDBCard>
            <MDBCard className="text-center mb-4 dark-grey-text">
              <MDBCardBody>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <h5 className="my-5 dark-grey-text font-weight-bold">Basic examples</h5>

        <MDBRow>
          <MDBCol md="12" lg="4" className="mb-4">
            <MDBCard className="card-body">
              <MDBCardTitle>Panel Title</MDBCardTitle>
              <MDBCardText className="mt-4 mb-4">Some quick example text to build on the panel title and make up the bulk of the panel's content.</MDBCardText>
              <div className="flex-row">
                <a href="#!">Card link</a>
                <a href="#!" style={{marginLeft: '1.25rem'}}>Another link</a>
              </div>
            </MDBCard>
          </MDBCol>

          <MDBCol md="6" lg="4" className="mb-4">
            <MDBCard>
            <MDBCardHeader color="deep-orange lighten-1">Featured</MDBCardHeader>
            <MDBCardBody>
              <MDBCardTitle>Special title treatment</MDBCardTitle>
              <MDBCardText>With supporting text below as a natural lead-in to additional content.</MDBCardText>
              <MDBBtn color="deep-orange">go somewhere</MDBBtn>
            </MDBCardBody>
          </MDBCard>
          </MDBCol>

          <MDBCol md="6" lg="4" className="mb-4">
            <MDBCard className="text-center">
              <MDBCardHeader color="success-color">Featured</MDBCardHeader>
              <MDBCardBody>
                <MDBCardTitle>Special title treatment</MDBCardTitle>
                <MDBCardText>With supporting text below as a natural lead-in to additional content.</MDBCardText>
                <MDBBtn color="success" size="sm">go somewhere</MDBBtn>
              </MDBCardBody>
              <MDBCardFooter color="success-color">2 days ago</MDBCardFooter>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <LinkCard docs="https://mdbootstrap.com/docs/react/components/panels/"/>

      </MDBContainer>
    </div>
  );
}

export default Panels;