// full pages here
import React from 'react';
import './App.css';
import { Switch, Route, Router } from 'react-router-dom';

import RoutesWithNavigation from './components/RoutesWithNavigation';

import Login from './components/pages/Login';
import Register from './components/pages/Register';
import Pricing from './components/pages/Pricing';
import Lock from './components/pages/Lock';
import About from './components/pages/About';
import SinglePost from './components/pages/SinglePost';
import PostListing from './components/pages/PostListing';
import Landing from './components/pages/Landing';

import handleActions, { init } from './uplift/actions';
import PageContainer from './uplift/PageContainer';
import { AppProvider, withAppContext, wrapWith } from "./uplift/AppContext";

import {
  withRouter
} from 'react-router-dom'

import { history } from './uplift/actions/history';
import ScrollToTop from "./uplift/utilities/ScrollToTop";
const queryString = require('query-string');

class AppContainer extends React.Component {
// const App = () => {
  constructor(props) {
    super(props);
    let initial_actions = [];
    if(process.env.REACT_APP_KEYCLOAK){
    initial_actions=[
      {type:process.env.REACT_APP_KEYCLOAK_REQUIRED_LOGIN==="true"?"REQUIRE_LOGIN":"CHECK_LOGIN"},
      ];
  }else{
      initial_actions=[
        
      ];
    }
    const h = handleActions(this);
    init(this); //TODO refactor this
    this.state ={ 
      handleActions: actions => h(actions),
      lan:"en"};
    this.state.handleActions(initial_actions);

    console.log(window.location.search);
    const params = queryString.parse(window.location.search);
    console.log(params);
    this.state.handleActions([{type:"SET_LANGUAGE",props:{lan:params.lan?params.lan:"en"}}])
  }
  getState(){
    return this.state;
  }

  render(){  return (
    <AppProvider value={this.state}>
          <Router history={history}>
            <div>
              <ScrollToTop />

              <Switch>
              <Route path='/' exact component={(props)=>withAppContext(PageContainer, {screen:require('./components/pages/workspace/pages/About').default}, props)} />
              <Route path='/faq' exact render={(props)=>withAppContext(PageContainer, {screen:require('./components/pages/workspace/pages/FAQ').default}, props)} />
              <Route path='/projects' exact render={(props)=>withAppContext(PageContainer, {screen:require('./components/pages/workspace/pages/Project').default}, props)} />
              <Route path='/pricing' exact component={require('./components/pages/workspace/pages/Pricing').default} />
              <Route path='/T&C' exact component={require('./components/pages/workspace/pages/T&C').default} />
              <Route path='/Privacy_Policy' exact component={require('./components/pages/workspace/pages/Privacy_Policy').default} />
              <Route path='/loading' exact render={(props)=>wrapWith(PageContainer, {screen:require('./common/Loading').default}, props)} />
              <Route path='/pages/login2' exact component={require('./components/pages/lap/LoginScreen').default} />
              <Route path='/pages/login' exact component={Login} />
              <Route path='/pages/register' exact component={Register} />
              <Route path='/pages/pricing' exact component={Pricing} />
              <Route path='/pages/lock' exact component={Lock} />
              <Route path='/pages/about' exact component={About} />
              <Route path='/pages/post' exact component={SinglePost} />
              <Route path='/pages/posts' exact component={PostListing} />
              <Route path='/pages/landing' exact component={Landing} />
              <RoutesWithNavigation/>
              </Switch>
              </div>
          </Router>
   </AppProvider>
    );
  }
}
export default withRouter(AppContainer)
//export default App;
