import React from 'react';
import {
  MDBContainer,
  MDBPopover,
  MDBPopoverHeader,
  MDBPopoverBody,
  MDBRow,
  MDBBtn
} from 'mdbreact';
import LinkCard from '../LinkCard';

const Popovers = () => {
  return (
    <div id="popovers">
      <MDBContainer fluid style={{ paddingTop: '150px' }}>

        <MDBRow center>
          <MDBPopover
            placement="top"
          >
            <MDBBtn>popover on top</MDBBtn>
            <div>
              <MDBPopoverHeader>popover on top</MDBPopoverHeader>
              <MDBPopoverBody>Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</MDBPopoverBody>
            </div>
          </MDBPopover>
          <MDBPopover
            placement="right"
          >
            <MDBBtn>popover on right</MDBBtn>
            <div>
              <MDBPopoverHeader>popover on right</MDBPopoverHeader>
              <MDBPopoverBody>Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</MDBPopoverBody>
            </div>
          </MDBPopover>
          <MDBPopover
            placement="bottom"
          >
            <MDBBtn>popover on bottom</MDBBtn>
            <div>
              <MDBPopoverHeader>popover on bottom</MDBPopoverHeader>
              <MDBPopoverBody>Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</MDBPopoverBody>
            </div>
          </MDBPopover>
          <MDBPopover
            placement="left"
          >
            <MDBBtn>popover on left</MDBBtn>
            <div>
              <MDBPopoverHeader>popover on left</MDBPopoverHeader>
              <MDBPopoverBody>Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</MDBPopoverBody>
            </div>
          </MDBPopover>
        </MDBRow>
        <LinkCard docs="https://mdbootstrap.com/docs/react/advanced/popovers/" />
      </MDBContainer>
    </div>
  );
}

export default Popovers;