import React from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBStep,
    MDBStepper,
    MDBView,
    MDBIcon,
    MDBBtn,
    MDBFileInput,
    MDBInput,
    MDBListGroup,
    MDBListGroupItem,
    MDBBadge,
    MDBTooltip,
    MDBModalHeader,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBMask,
    MDBFormInline,
} from 'mdbreact';

import "./onboard.css";

import {
  i18n,
} from 'web-translate';

import update from 'immutability-helper';
import { getHandler } from "../../../uplift/actions"
const moment = require('moment-timezone');
const _ = require('lodash');


const data = [
    {
        icon:"tools",
        title:"Digital Resource Sharing App (This is how this website is built)",
        text: 
`A User can create multiple Thread.
A Thread groups a few Messages.
A Message can contain mulitple TextResource and/or UriResource.
A TextResource contains a text body.
A UriResource contains a File.
A File describe where the File is stored and whether as a private or public File.
Thread Creator can view, modify and delete the Thread.
Thread Creator can view, modify and delete all the sub-resources under Thread, i.e. Message, TextResource and UriResource/File.
Admin User can view all Users' sub-resources under Thread, i.e. Message, TextResource and UriResource/File.
Admin User can reply to other Users' Message, by creating a new Message and share it with the User.
`
    },
    {
        icon:"shopping-cart",
        title:"Online Shopping/Order App",
        text: 
`A User can order many Orders from a Store.
A User has multiple payment accounts, with one being default one.
A User can pay an Order.
An Order has many Products.
An Order has one Fulfilment option, i.e. "pickup", "deliver".
A Fulfilment has one Delivery address.
Each Product has its Inventory and pricing.
An Owner can have many Shops.
A Shop has many Categories.
A Category has many Products.
A Shop have many special Offers that have different pricing.
An Offer contains multiple Products and an Order can buy many Offers and/or Products.
Shop Owner can view Shop's Orders, assign Deliver.
Shop Owner can get sales report regarding to Orders, Offers, Products, Customers.`
    },
    {
        icon:"calendar-alt",
        title:"Roaster App",
        text:
`A User input multiple skill set.
Employer can verify a user's skill.
A User has time availability in his calender.
An Employer has multiple Shops, each Shop has its own Roaster to fill.
A User can cancel his Roaster, then Employer will receive a notification.
Employer can search available User by ratings etc, to assign the Roast, then this User will be notified and provide confirmation.`
    },
    {
        icon:"home",
        title:"Rental Property Management App",
        text:
`Home Owner onboards his Property's information into the App.
Agent manages Property operational instructions such as Key handling instructions.
Agent manages ad-hoc property repair request.
Online booking orders integration to trigger agent service workflows.
A room booking triggers booking of Cleaning service or/and mattress Deliver service
A room booking triggers an invoice for above Cleaning and Deliver services to Home Owner.
Property agent confirms the agent for Cleaning/Deliver services, and upon finish confirms their task quality
Reporting for the properties to home Owner.
Tenant management such as feedback, enquires and disputes.`
    }
]

const components = [ "Backend API", "IOS", "ANDROID", "WEBAPP", "WEBSITE", "User Management", "Business Reporting", "Third-party Integration", "Task Scheduling"];
export default class Screen extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            File:[],
            Thread:[],
        };
    }
 
    componentDidMount(){
        getHandler()([{ 
            type: 'HTTP4',  
            props: {
                loginRequired:true,
                onComplete:()=>{},
                noSpinner:false,
                method:'GET',
                path: `/website/dashboard/onboard/sketch/upsert`,
                }
            }], this.props.parent, this); 
    }
    deleteFile(id){
        getHandler()([{ 
            type: 'HTTP4',  
            props: {
                onComplete:()=>{},
                noSpinner:false,
                method:'DELETE',
                path: `/website/dashboard/onboard/sketch/upsert?id=${id}`,
                }
            }], this.props.parent, this); 
    }
    toggleExample = async () => {
        await this.requireRegistration();
        this.setState({example: !this.state.example})
    }
    toggleDomain(){
        this.setState({domain: !this.state.domain})
    }
    requireRegistration = async () => {
        await getHandler()([{"type":"CLIENT_REQUIRE_REGISTRATION"}], this.props.parent, this);
    }
    copyDomain = async (text) => {
        //await this.requireRegistration();
        if (this.state.Thread[0] && this.state.Thread[0].Messages[0])
        this.setState(update(this.state,{
            Thread:{
                [0]: {
                    Messages: {
                        [0]: 
                        {
                            TextResources:{
                                $upsert_array:{
                                    value: {
                                        name: "initial_requirement",
                                        body: text
                                    },
                                    predicate: tr=>tr.name=='initial_requirement'
                                }
                            }
                        }
                      }
                  }
              }
          }));
          else this.setState({Thread:[{Messages:[{TextResources:[
              {
                  name: "initial_requirement",
                  body:text
                }
            ]}]}]});
        this.toggleDomain();
    }
    uploadSketch =  async () => {
        await this.requireRegistration();
        if(!this.state.submitting && this.state.files){
            const file = this.state.files[0];
            getHandler()([{
                type:"WEB_FORMDATA",
                props:
                {
                    private_file:true,
                    requireLogin:true,
                    opts:{
                    url: (process.env.REACT_APP_SERVER_HOSTONLY)+'/file/multipart',
                    file
                    },
                    onComplete:(data)=>{
                        console.log('done',data); 
                        this.setState({uploading:false}); 
                        getHandler()([{ 
                            type: 'HTTP4',  
                            props: { 
                            onComplete: ()=>{ this.setState({submitting:false});},
                            noSpinner:false,
                            method:'PUT',
                            path: '/website/dashboard/onboard/sketch/upsert', 
                            data: {
                                form: {
                                    uri:data.data.url,
                                    name: file.name,
                                    metatype: file.type,
                                    private: true,
                                },
                                } 
                            } 
                            }], this.props.parent, this); 
                        this.setState({submitting:false});
                    }, 
                    onStart: (data)=>{
                        console.log('start',data);
                        this.setState({uploading:true});
                    }, 
                    onProgress: (data)=>{ 
                        //this.setState({progress:data.progress/100}); //TODO why does it crash the app for large file
                        console.log('progress',data.progress);
                    },
                    onError: (data)=>{console.log('upload error',data); this.setState({submitting:false});}
                }
            }], this.props.parent, this
            );
        }
    }
    save = async () => {
        await this.requireRegistration();
        
                        this.setState({uploading:false}); 
                        getHandler()([{ 
                            type: 'HTTP4',  
                            props: { 
                            onComplete: ()=>{ this.setState({submitting:false});},
                            noSpinner:false,
                            method:'PUT',
                            path: '/website/dashboard/onboard/sketch/upsert', 
                            data: {
                                form: {
                                    domainText: _.get(this.getDomainData(), "[0].body", ""),
                                    components: JSON.stringify(this.getComponentsData()),
                                    oneOffBudget:  this.getTextResource("oneOffBudget"),
                                    reoccurringBudget:  this.getTextResource("reoccurringBudget"),
                                    entityInfo:  this.getTextResource("entityInfo"),
                                    contact:  this.getTextResource("contact"),

                                },
                                } 
                            } 
                            }], this.props.parent, this); 
                        this.setState({submitting:false});
    }
    handleInput = e => {
        this.requireRegistration();
        if (this.state.Thread[0] && this.state.Thread[0].Messages[0])
        this.setState(update(this.state,{
            Thread:{
                [0]: {
                    Messages: {
                        [0]: {
                            TextResources:{
                                $upsert_array:{
                                    value: {
                                        name: "initial_requirement",
                                        body: e.target.value
                                    },
                                    predicate: tr=>tr.name=='initial_requirement'
                                }
                            }
                        }
                      }
                  }
              }
          }));
          else this.setState({Thread:[{Messages:[{TextResources:[
            {
                name: "initial_requirement",
                body: e.target.value
            }
          ]}]}]});
      }
    getComponentsData = () => {
        const filtered =  (_.get(this.state, "Thread[0].Messages[0].TextResources", [])).filter(tr=>tr.name=='components');
        if(filtered.length==0) return [];
        else return JSON.parse(filtered[0].body);
    }
    getDomainData = () => {
        return _.get(this.state, "Thread[0].Messages[0].TextResources", []).filter(tr=>tr.name=='initial_requirement');
    }
    // entityInfo
    // reoccurringBudget
    // oneOffBudget
    getTextResource = (field) => {
        return _.get(_.get(this.state, "Thread[0].Messages[0].TextResources", []).filter(tr=>tr.name==field), "[0].body", "");;
    }

    componentsUpdated = async (v)=>{
        await this.requireRegistration();
        let oldC = this.getComponentsData();
        const index = oldC.findIndex(e=>e==v);
        if(index>-1) {
            oldC.splice(index,1); 
        }else {
            oldC.push(v);
        }
        const newC = oldC;
        if (this.state.Thread[0] && this.state.Thread[0].Messages[0])
        this.setState(update(this.state,{
            Thread:{
                [0]: {
                    Messages: {
                        [0]: {
                            TextResources:{
                                $upsert_array:{
                                    value: {
                                        name: "components",
                                        body: JSON.stringify(newC)
                                    },
                                    predicate: tr=>tr.name=='components'
                                }
                            }
                        }
                      }
                  }
              }
          }));
          else this.setState({Thread:[{Messages:[{TextResources:[
            {
                name: "components",
                body: JSON.stringify(newC)
            }
          ]}]}]});
    }

    onOneOff = v => () => {
        console.log(`v=${v}`, `this.state.oneOff==${this.state.oneOff}`)
        this.setState({
          oneOff: v
        });
        if (v)  this.getTextResource("reoccurringBudget") && this.changeBasicHandler({target:{name:"reoccurringBudget", value:""}});
        else  this.getTextResource("oneOffBudget") && this.changeBasicHandler({target:{name:"oneOffBudget", value:""}});
        console.log(`this.state.oneOff==${this.state.oneOff}`)
      };

    changeBasicHandler = (e) => {
        
        //await this.requireRegistration();
        if (this.state.Thread[0] && this.state.Thread[0].Messages[0])
        this.setState(update(this.state,{
            Thread:{
                [0]: {
                    Messages: {
                        [0]: {
                            TextResources:{
                                $upsert_array:{
                                    value: {
                                        name: e.target.name,
                                        body: e.target.value
                                    },
                                    predicate: tr=>tr.name==e.target.name
                                }
                            }
                        }
                      }
                  }
              }
          }));
          else this.setState({Thread:[{Messages:[{TextResources:[
            {
                name: e.target.name,
                body: e.target.value
            }
          ]}]}]});
    };
    submitBasic = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        //  console.log(event, this.getTextResource("reoccurringBudget"), this.getTextResource("oneOffBudget"), this.getTextResource("contact"), this.getTextResource("entityInfo"));
        if ((!this.state.oneOff && this.getTextResource("reoccurringBudget") ||
        (this.state.oneOff && this.getTextResource("oneOffBudget"))) && this.getTextResource("contact") && this.getTextResource("entityInfo"))
        this.save();
    };
    render() {
        const cData = this.getComponentsData();
        const domainData = this.getDomainData();
        const entityInfo = this.getTextResource("entityInfo");
        const reoccurringBudget =this.getTextResource("reoccurringBudget");
        const oneOffBudget =  this.getTextResource("oneOffBudget");
        const contact =  this.getTextResource("contact");
        console.log("===",this.state.oneOff, reoccurringBudget, oneOffBudget)
  return (
    <MDBContainer fluid className="p-0">
            <MDBModal isOpen={this.state.example} toggle={() => this.toggleExample()} className="modal-notify modal-success" size="fluid">
              <MDBModalHeader tag="p" toggle={() => this.toggleExample()} className="light-blue darken-3 white-text">
              Currently, We only accept <a href={"https://www.sketch.com/"} target={"_blank"}><strong  className="light-blue darken-3 white-text">sketch</strong></a> file.
                </MDBModalHeader>
              <MDBModalBody className="text-center">
                {/*<MDBIcon  fab icon="sketch yellow-text" className="animated rotateIn mb-3" size="4x"/><MDBBtn size="sm" color="black" onClick={() => this.toggleExample()} className="d-inline ml-2 px-2"> <MDBIcon icon="times" /></MDBBtn>
                <p>Currently, We only accept sketch design.</p>*/}
                <MDBView hover className="z-depth-1">
                              <img style={{display:"inline"}} src="https://i.pinimg.com/564x/52/60/e3/5260e3a5c15a22c73848ac9092cc5022.jpg" className="img-fluid" alt="First sample"/>
                              <a href="#!">
                                  <MDBMask overlay="white-slight"/>
                              </a>
                </MDBView>
              </MDBModalBody>
              {/*<MDBModalFooter end>
                <MDBBtn color="success" onClick={() => this.toggle(9)}>Get it now <MDBIcon icon="diamond white-text ml-1"/></MDBBtn>
                <MDBBtn color="success" outline onClick={() => this.toggle(9)}>No, thanks</MDBBtn>
              </MDBModalFooter>*/}
            </MDBModal>

<MDBModal isOpen={this.state.domain} toggle={() => this.toggleDomain()} className="modal-notify modal-success" size="fluid">
              <MDBModalHeader tag="p" toggle={() => this.toggleDomain()} className="light-blue darken-3 white-text">

                </MDBModalHeader>
              <MDBModalBody className="text-center">
                {/*<MDBIcon  fab icon="sketch yellow-text" className="animated rotateIn mb-3" size="4x"/><MDBBtn size="sm" color="black" onClick={() => this.toggleExample()} className="d-inline ml-2 px-2"> <MDBIcon icon="times" /></MDBBtn>
                <p>Currently, We only accept sketch design.</p>*/}
                <MDBView hover className="z-depth-1">
                <section className="text-center my-5">
      <h2 className="h1-responsive font-weight-bold text-center my-5">
        {i18n("Select a template")}
      </h2>
      <p className="grey-text text-center w-responsive mx-auto mb-5">
      
      </p>
      <MDBRow>
          {data.map((d,i)=><MDBCol key={i} lg="4" md="12" className="mb-lg-0 mb-4">
          <MDBCard>
            <MDBCardBody>
              <h5 className="mb-4">{d.title}</h5>
              <div className="d-flex justify-content-center">
                <div className="card-circle d-flex justify-content-center align-items-center">
                 <h1><MDBIcon icon={d.icon} className="indigo-text" /></h1>
                </div>
              </div>
              <ul className="list-unstyled">
              {d.text.split("\n").map((l,i)=><li key={i} className="grey-text text-justify">{l}</li>)}
              </ul>
              <MDBBtn rounded color="indigo" onClick={()=>this.copyDomain(d.text)}>
                Copy this
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>)}
        
        </MDBRow>
    </section>
               </MDBView>
              </MDBModalBody>
              {/*<MDBModalFooter end>
                <MDBBtn color="success" onClick={() => this.toggle(9)}>Get it now <MDBIcon icon="diamond white-text ml-1"/></MDBBtn>
                <MDBBtn color="success" outline onClick={() => this.toggle(9)}>No, thanks</MDBBtn>
              </MDBModalFooter>*/}
            </MDBModal>

            <section>
            <MDBCard narrow>
                <MDBView cascade className="gradient-card-header blue-gradient">
                        <h5 className="mb-0">{i18n("Essential Information for Free Prototype")}</h5>
                </MDBView>
            <MDBCardBody cascade>
                <MDBRow className="mt-1">
                     <MDBCol md="12" className="text-left  mx-2">
                           
                           <p>{i18n("Please provide accurate information so that we can correctly verify and proceed further. In case we can't fulfill every request due to our limited resource, we will prioritize the prototype work with client who has higher chance to engage with us. Feel free to ask us via the chat button for any question.")}</p>
                           <MDBFormInline>
                            <label className="mr-1">{i18n("Service Type")}</label>
                            <MDBInput
                            onClick={this.onOneOff(false)}
                            checked={((!this.state.oneOff && !oneOffBudget) || reoccurringBudget) ? true : false}
                            label={i18n("Build and Host")}
                            type='radio'
                            id='radio2'
                            containerClass='mr-1'
                            />
                            <MDBInput
                            onClick={this.onOneOff(true)}
                            checked={!((!this.state.oneOff && !oneOffBudget) || reoccurringBudget) ? true : false}
                            label={i18n("One-Off")}
                            type='radio'
                            id='radio1'
                            />
                            
                        </MDBFormInline>
                        <form 
                           className="needs-validation"
                           onSubmit={this.submitBasic}
                           noValidate>
                        {((!this.state.oneOff && !oneOffBudget) || reoccurringBudget) &&<MDBInput  name="reoccurringBudget" value={reoccurringBudget} type="text" label={i18n("Your monthly budget for this APP in AUD")} icon="dollar-sign" required onChange={this.changeBasicHandler}/>}
                        {!((!this.state.oneOff && !oneOffBudget) || reoccurringBudget) &&<MDBInput  name="oneOffBudget" value={oneOffBudget} type="text" label={i18n("Your budget for this APP in AUD")} icon="dollar-sign" required onChange={this.changeBasicHandler}/>}
                        <MDBInput type="text" name="entityInfo" value={entityInfo} label={i18n("Your company/personal name/website")} icon="user" required onChange={this.changeBasicHandler}/>
                        <MDBInput type="number" name="contact" value={contact} label={i18n("Contact number")} icon="phone-square" required onChange={this.changeBasicHandler}/>
                          
                          <div className="text-right">
                            <MDBBtn size="sm" color="primary" type="submit">Save</MDBBtn>
                          </div>
                          </form>
                </MDBCol>
                </MDBRow>
                </MDBCardBody>
                </MDBCard>
            </section>
            <hr className="mb-5"/>
            <section>
            <MDBCard narrow>
                <MDBView cascade className="gradient-card-header blue-gradient">
                        <h5 className="mb-0">{i18n("What services do you need?")}</h5>
                </MDBView>
            <MDBCardBody cascade>
                <MDBRow className="mt-1">
                    <MDBCol md="12">
                        {/*<p className="text-justify mb-4">What components do you need?</p>*/}
                {/* Filled-in inline */}
                <MDBFormInline>
                    {components.map(c=><MDBInput
                    key={c}
                    label={c}
                    value={c}
                    filled
                    checked={cData.indexOf(c)>-1}
                    type='checkbox'
                    id={c}
                    containerClass='mr-2'
                    getValue={this.componentsUpdated}
                    />)}
                </MDBFormInline>
                </MDBCol>
                <MDBCol md="12" className="text-right">
                                        {/*<MDBBtn flat>Cancel</MDBBtn>*/}
                                        <MDBBtn size="sm" color="primary" onClick={()=>this.save()}>Save</MDBBtn>
                                    </MDBCol>
                </MDBRow>
                </MDBCardBody>
                </MDBCard>
            </section>
            
            {(true||cData.includes("IOS")||cData.includes("ANDROID")||cData.includes("WEBAPP")||cData.includes("WEBSITE"))
            &&<>
            <hr className="mb-5"/>
            <section>
                <MDBCard narrow>
                    <MDBView cascade className="gradient-card-header peach-gradient">
                        <h5 className="mb-0">{i18n("When you have screen design")}</h5>
                    </MDBView>

                    <MDBCardBody cascade>
                        <MDBRow className="mt-1">
                            <MDBCol md="12">
                                <MDBStepper vertical>
                                <MDBStep className="active">
                                    <a href="#!">
                                        <span className="circle">1</span>
                                        <span className="label">{i18n("Upload your screen design")}</span>
                                    </a>
                                    <MDBCol md="12" className="mb-4">
                                    <div className="step-content">
                                             <span className="label">{i18n("What does the your App look like? How does each screen navigate? Having your screen design will save both of us significant time and effort, because the designing process itself can clarify your idea. Having such a design means that all the back and forth discussions are skipped and we simply will get what you can see. Currently we only accept sketch files, checkout examples: ")}<MDBBtn size="sm" outline color="info" onClick={() => this.toggleExample()} className="d-inline ml-2 px-2">{i18n("Examples")}</MDBBtn></span>
                                    <MDBFileInput textFieldTitle={i18n("Upload sketch file")} getValue={(e)=>{this.setState({files:e});console.log(e)}}/>
                                    <MDBRow className="mt-1">
                                    <MDBCol md="12" className="text-right">
                                        {/*<MDBBtn flat>Cancel</MDBBtn>*/}
                                        <MDBBtn size="sm" color="primary" onClick={()=>this.uploadSketch()}>Upload</MDBBtn>
                                    </MDBCol>
                                    </MDBRow>
                                    <MDBListGroup className="step-content list-group">
                                        {_.get(this.state, "Thread[0].Messages[0].UriResources") && 
                                        _.get(this.state, "Thread[0].Messages[0].UriResources").map(ur=>{
                                            const t = ur.File
                                        return <div key={t.id}>
                                            <MDBListGroupItem id={t.id} tag="a" href="#!" hover className="d-flex justify-content-between dark-grey-text" style={{padding:"5px"}}>
                                            <div className="justify-content-start">
                                                <span className="d-inline-block text-truncate" style={{maxWidth: "50vw"}} title={`${t.name}, created at ${t.createdAt}`}>{t.name}</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                            <MDBBtn size="sm" outline color="info" onClick={()=>this.downLoad(t.id)} className="d-inline ml-2 px-2"> <MDBIcon icon="download" /> </MDBBtn>
                                            
                                            <MDBBtn size="sm" outline color="info" onClick={()=>this.deleteFile(ur.id)} className="d-inline ml-2 px-2"><MDBIcon  far icon="trash-alt" /></MDBBtn>
                                                                      
                                            
                                            </div>
                                            
                                           </MDBListGroupItem>
                                           
                                            </div>}
                                            )
                                        }
                                    </MDBListGroup>
                                    </div>
                                    </MDBCol>
                                </MDBStep>

                                <MDBStep className="active">
                                    <a href="#!">
                                        <span className="circle">2</span>
                                        <span className="label dark">{i18n("Preview free demo")}</span>
                                    </a>
                                    <MDBCol md="12" className="mb-4">
                                    <div className="step-content">
                                             <span className="label">
                                             {i18n("Preview free demo of the web/app we built from your design. Depends on our availability and complexity of your requirement, the building time can take a few days to weeks. We will contact you once ready. Please make sure you provide your mobile and company details")}<a style={{  padding: 0, display: "inline"}} href={`${process.env.REACT_APP_KEYCLOAK_ACCOUNT}`} target="_blank"><strong>{i18n("here")}</strong></a> {i18n("so that we can contact you for further information when required.")}</span>
                                    </div>
                                    </MDBCol>
                                </MDBStep>
                                <MDBStep>
                                    <a href="#!">
                                        <span className="circle">3</span>
                                        <span className="label">{i18n("Contract and initial payment")}</span>
                                    </a>
                                </MDBStep>
                                <MDBStep>
                                    <a href="#!">
                                        <span className="circle">4</span>
                                        <p className="label">{i18n("Preview full website/app")}</p>
                                    </a>
                                    <MDBCol md="12" className="mb-4">
                                    <div className="step-content">
                                             <span className="label">{i18n("This preview will use hard-coded data, which will be integrated with backend in next Step")}</span>
                                    </div>
                                    </MDBCol>
                                    
                                </MDBStep>
                                <MDBStep>
                                    <a href="#!">
                                        <span className="circle">5</span>
                                        <span className="label">{i18n("Integration with backend data")}</span>
                                    </a>
                                </MDBStep>
                                <MDBStep>
                                    <a href="#!">
                                        <span className="circle">6</span>
                                        <span className="label">{i18n("Preview, test of website, app")}</span>
                                    </a>
                                </MDBStep>
                            </MDBStepper>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>

            </section>
            </>
            }
            
            {(true||(cData.includes("Backend API")))
            &&<>
            <hr className="mb-5"/>
            <section>
                <MDBCard narrow>
                    <MDBView cascade className="gradient-card-header purple-gradient">
                        <h5 className="mb-0">{i18n("When you need a backend")}</h5>
                    </MDBView>

                    <MDBCardBody cascade>
                        <MDBRow className="mt-1">
                            <MDBCol md="12">
                                <MDBStepper vertical>
                                <MDBStep className="active">
                                    <a href="#!">
                                        <span className="circle">1</span>
                                        <span className="label">{i18n("Describe APP's use case")}</span>
                                    </a>
                                    <MDBCol  md="12" className="mb-4">
                                        <div className="step-content">
                                            <span className="label">{i18n("What's your APP's major use case? Who are involved and what are actioned on? Start from our generic template here: ")}<MDBBtn size="sm" outline color="info" onClick={() => this.toggleDomain()} className="d-inline ml-2 px-2">{i18n("Examples")}</MDBBtn></span>
                                            <form>
                                                <MDBInput type="textarea" 
                                                label={i18n("Describe your use case")}
                                                value={_.get(domainData, "[0].body", "")} 
                                                outline
                                                rows={_.get(domainData, "[0].body", "")?_.get(domainData, "[0].body", "").split("\n").length+1:1}
                                                icon="pencil"
                                                name="domainText"
                                                onInput={this.handleInput}
                                                />
                                            </form>
                                        <MDBRow className="mt-1">
                                        <MDBCol md="12" className="text-right">
                                            {/*<MDBBtn flat>Cancel</MDBBtn>*/}
                                            <MDBBtn size="sm" color="primary" onClick={()=>this.save()}>{i18n("Save")}</MDBBtn>
                                        </MDBCol>
                                        </MDBRow>
                                        </div>
                                    </MDBCol>
                                    
                                </MDBStep>
                                {/*}
                                <MDBStep className="active">
                                    <a href="#!">
                                        <span className="circle">2</span>
                                        <span className="label">Draw your data relationship instead</span>
                                    </a>
                                    <MDBCol md="12" className="mb-4">
                                        <ReactIframeResizer 
                                        frameBorder={1}
                                        style={{ width:"100%", height:800 }}
                                        src="https://www.draw.io/"
                                        iframeResizerOptions={{checkOrigin: false}}>
                                        </ReactIframeResizer>
                                    </MDBCol>
                                </MDBStep>*/}
                                <MDBStep className="active">
                                    <a href="#!">
                                        <span className="circle">2</span>
                                        <span className="label">{i18n("Free demo of backend api and data model diagram")}</span>
                                    </a>
                                    <MDBCol md="12" className="mb-4">
                                    <div className="step-content">
                                             <span className="label">{i18n("We will build the backend api per your description. Depends on our availability and complexity of your requirement, the building time can take a few days to weeks. Please wait until we finish building your database. We will contact you once ready. Please make sure you provide your mobile and company details")}<a  style={{  padding: 0, display: "inline"}} href={`${process.env.REACT_APP_KEYCLOAK_ACCOUNT}`} target="_blank"><strong>{i18n("here")}</strong></a> {i18n("so that we can contact you for further information when required.")}</span>
                                    </div>
                                    </MDBCol>
                                </MDBStep>
                                <MDBStep>
                                    <a href="#!">
                                        <span className="circle">3</span>
                                        <span className="label">{i18n("Contract and initial payment")}</span>
                                    </a>
                                </MDBStep>
                                <MDBStep>
                                    <a href="#!">
                                        <span className="circle">4</span>
                                        <span className="label">{i18n("Define screen navigation")}</span>
                                    </a>
                                </MDBStep>
                                <MDBStep>
                                    <a href="#!">
                                        <span className="circle">5</span>
                                        <span className="label">{i18n("Define involved data for each screen")}</span>
                                    </a>
                                </MDBStep>
                                
                                <MDBStep>
                                    <a href="#!">
                                        <span className="circle">6</span>
                                        <span className="label">{i18n("Integration with backend data")}</span>
                                    </a>
                                </MDBStep>
                                <MDBStep>
                                    <a href="#!">
                                        <span className="circle">7</span>
                                        <span className="label">{i18n("Preview, test of website, app")}</span>
                                    </a>
                                </MDBStep>
                            </MDBStepper>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>

            </section>
            </>
            }
    </MDBContainer>
  );
    }
}
