"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setApiKey = setApiKey;
exports.setEngine = setEngine;
exports.translate = translate;

var _index = _interopRequireDefault(require("./translate-franciscop/index"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

_index.default.engine = 'yandex';

function setApiKey(apiKey) {
  _index.default.key = apiKey;
}

function setEngine(engine) {
  _index.default.engine = engine;
}
/**
 * Translates text from one language to another.
 * `apiKey` is an API key for the Yandex or Google Translate services.
 * `from` and `to` are language codes.
 */


function translate(from, to, text) {
  // No need to translate whitespace.
  if (!text.trim()) return text;
  _index.default.from = from;
  return (0, _index.default)(text, {
    to: to
  });
}