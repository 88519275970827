import React from 'react';
import ReactIframeResizer from 'react-iframe-resizer-super';
 
 
const iframeResizerOptions = { 
    checkOrigin: false
 };
 
const MyComponent = props => (
  <div>
    <p>Content Before Iframe (style unaffected by iframe)</p>
    
    <ReactIframeResizer 
    frameBorder={1}
    style={{ width:"100%", height:800 }}
    src="https://app.slack.com/client/TDEQVBUHY/CDF1534SH"
    iframeResizerOptions={iframeResizerOptions}>
    </ReactIframeResizer>

    <ReactIframeResizer 
    frameBorder={1}
    style={{ width:"100%", height:800 }}
    src="https://www.draw.io/#G1jf3H06Gk35jnZVBKb2UMdYOS3NsCGWKY"
    iframeResizerOptions={iframeResizerOptions}>
    </ReactIframeResizer>
    <p>Content After Iframe (style unaffected by iframe)</p>
  </div>
);

export default MyComponent;