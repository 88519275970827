var crypto = require('crypto');

function getAlgorithm(key) {

    //var key = Buffer.from(keyBase64, 'base64');
    switch (key.length) {
        case 16:
            return 'aes-128-cbc';
        case 32:
            return 'aes-256-cbc';

    }

    throw new Error('Invalid key length: ' + key.length);
}
export default 
function constructor(key, iv){
    /*let buff = new Buffer(key);
    const keyBase64 = buff.toString('base64');
    buff = new Buffer(iv);
    const ivBase64 = buff.toString('base64');*/
    
    return {
        // String -> encrypted Base64
        encrypt: plainText => { 
            //cipher.update(plainText, 'utf8', 'base64');
            const cipher = crypto.createCipheriv(getAlgorithm(key), key, iv);
            let cip = cipher.update(plainText, 'utf8', 'base64')
            cip += cipher.final('base64');
            return cip
        },
        // encrypted Base64 -> original String
        decrypt: messagebase64 =>{
            const decipher = crypto.createDecipheriv(getAlgorithm(key), key, iv);
            let dec = decipher.update(messagebase64, 'base64');
            dec += decipher.final();
            //console.log("dec========",dec)
            return String.raw`${dec}`;
        }
    }
}


/*


const key = "Bar12345Bar12341", iv = "RandomInitVector";
const c = constructor(key, iv);

var plainText = 
`

[
    {
  "type":"SET_LAYOUT",
  "props":{  
     "type":"call",
     "value":"createAppContainer",
     "args":[  
        {  
          "type":"call",
          "value":"createStackNavigator",
          "args":[
             {  "type":"object",
                "value":{
                   "Login":{
                      "type":"object",
                      "value":{  
                         "screen":{  
                            "type":"GlobalContextProvider",
                               "value": { 
                                  "path":"/p/app/login"
                            }
                         }
                      }
                   }
                }
             }
             ,
             {
                "type":"primitive",
                "value":{
                   "initialRouteName": "Login",
                   "headerMode": "none"
                }
             }
          ]
      }
      ]
  }
},
 {  
    "type":"FIRST_LOAD_DONE"
 }
]


`;
var cipherText = c.encrypt(plainText);
var decryptedCipherText = c.decrypt(cipherText);
console.log('Algorithm: ' + getAlgorithm(key));
console.log('Plaintext: ' + plainText);
console.log('Ciphertext: ' + cipherText);
console.log('Decoded Ciphertext: ' + decryptedCipherText);
console.log(JSON.parse(decryptedCipherText))
/*
Algorithm: aes-128-cbc
Plaintext: Hello World
Ciphertext: DcTNTEKGr29u9fqwxzdS9Q==
Decoded Ciphertext: m

 */