import React from "react";

import { EventEmitter } from 'events';

const AppContext = React.createContext({app:{}});

export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;

export class ContextProvider extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
        // testing state mutation in children under one component root, works
        setInterval(()=>{ console.log(this.state.routePath);this.setState({routePath:Math.random()})},2000)
    }
    /*componentDidMount() {
        emitter.on('new_customer_status', this.listenForCustomerStatus);
    }

    componentWillUnmount() {
        emitter.removeListener('new_customer_status', this.listenForCustomerStatus);
    }

    listenForCustomerStatus = () => {
        this.forceUpdate();
    };*/

    render() {

        return (
            <AppProvider value={ this.state }>
                {this.props.children}
            </AppProvider>
        );
    }
}

// TODO how to use it in Navigation.registerComponent('PocW', () => (props) => ( ??
export function withAppContext(Component, props) {
    return (
        
            <AppConsumer>
                {context => <Component {...props} context={context} />}
            </AppConsumer>
        
    );
}

export function wrapWith(Component, props, parentProps) {
    return <Component {...props} {...parentProps} parent={this}></Component>;
}

const AppGlobalContext = React.createContext({});

export const AppGlobalContextProvider = AppGlobalContext.Provider;
export const AppGlobalContextConsumer = AppGlobalContext.Consumer;


let globalState = {app:{}, _components:{}}; // app is app specic properites

const emitter = new EventEmitter();
// testing, works
// setInterval(()=>{ console.log(globalState.routePath); setState({routePath:Math.random()});},2000)
export function setState (param, noEmit) {
    if(typeof param === 'function') {
        globalState = param(globalState); // run function that passed and get the returned object
      // set new state with newState
    }
    else {
        globalState = {...globalState, ...param}; // use passed object
    }
    // set new state with newState
    //if(globalState.pages['Dynamic'])console.log("_STATE_CHANGED, globalState.pages['Dynamic']:", JSON.stringify(globalState.pages['Dynamic']));
    //console.log("_STATE_CHANGED");
    if(!noEmit) emitter.emit("_STATE_CHANGED");
}
function getState () { return  globalState };
export const Store = { setState, getState, state: globalState }  
export class GlobalContextProvider extends React.Component {
    constructor(props){
        super(props);
        console.log("CONSTRUCT GlobalContextProvider..", props._key);
    }
    componentDidMount() {
        emitter.on('_STATE_CHANGED', this.listenForStateChanged);
    }

    componentWillUnmount() {
        emitter.removeListener('_STATE_CHANGED', this.listenForStateChanged);
    }

    listenForStateChanged = () => {
        console.log("listenForStateChanged->this.forceUpdate(): ",  this.props._key);
        this.forceUpdate();
    };

    render() {

        return (
            <AppGlobalContextProvider value={ globalState }>
                {this.props.children}
            </AppGlobalContextProvider>
        );
    }
}