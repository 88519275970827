import React from 'react';
import { 
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBView,
} from 'mdbreact';

import GoogleMapReact from 'google-map-react';

const Google = () => {
  return (
    <div id="maps-google">
      <MDBContainer fluid>
        <MDBRow>
          <MDBCol md="6">
            <MDBCard className="mt-5" narrow >
                <MDBView cascade className="gradient-card-header mdb-color lighten-3">
                  <h4 className="h4-responsive text-white">
                    Regular map
                  </h4>
                </MDBView>
                <MDBCardBody style={{width: '100%', height: '400px'}} className="text-center">
                  <GoogleMapReact
                    defaultCenter={{lat: 10, lng: 10}}
                    defaultZoom={7}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol md="6">
              <MDBCard className="mt-5" narrow>
                <MDBView cascade className="gradient-card-header mdb-color lighten-3">
                  <h4 className="h4-responsive text-white">
                    Terrain map
                  </h4>
                </MDBView>
                <MDBCardBody style={{width: '100%', height: '400px'}} className="text-center">
                  <GoogleMapReact
                          defaultCenter={{lat: 10, lng: 10}}
                          defaultZoom={7}
                          options = {{
                            mapTypeId: 'terrain'
                          }}
                        />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>         
          </MDBRow>
          <MDBRow>
            <MDBCol md="12">
              <MDBCard className="mt-5" narrow>
                <MDBView cascade className="gradient-card-header mdb-color lighten-3">
                  <h4 className="h4-responsive text-white">
                    Satellite map
                  </h4>
                </MDBView>
                <MDBCardBody style={{width: '100%', height: '400px'}} className="text-center">
                  <GoogleMapReact
                        defaultCenter={{lat: 10, lng: 10}}
                        defaultZoom={7}
                        options = {{
                          mapTypeId: 'satellite'
                        }}
                      />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
    </div>
  );
}

export default Google;