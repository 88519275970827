import React from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBFormInline,
  MDBRow,
  MDBCol,
  MDBAvatar,
  MDBMask,
  MDBIcon,
  MDBView,
  MDBBtn,
  MDBCardBody,
  MDBInput,
  MDBFooter,
  MDBCard,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
} from 'mdbreact';
import Lightbox from "react-image-lightbox";
import "./About.css";
import Copyrights from '../../../Footer';

import YouTube from '@u-wave/react-youtube';
import windowSize from 'react-window-size';
import ModalImage from "react-modal-image";

import { Container, Button, lightColors, darkColors } from 'react-floating-action-button'
import { getHandler } from '../../../../uplift/actions';
import NavBar from "./navbar";
import {
  i18n,
} from 'web-translate';



class Screen extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      collapseID: "",
      photoIndex: 0,
      isOpen: false
    };
  }

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));

    render() {
      const { photoIndex, isOpen } = this.state;

  return (
    <div id="about">
      <Container styles={{
            left: "2%",
            zIndex: 1,
            width: "30px",
            bottom: "4%",
          }}>
              <Button
              tooltip="Demo"
              styles={{backgroundColor: darkColors.lightBlue, color: lightColors.white}}
              onClick={()=>getHandler()([{type:"GOTO",props:{path:"/onboard"}}])}
              ><h6 className="white-text" style={{fontSize: "12px"}}>{i18n("Free Prototype")}</h6></Button>
          </Container>
          <NavBar parent={this}/>
          <MDBContainer>
          <br/>
          <section className="text-center my-5">
        <h2 className="h1-responsive font-weight-bold my-5">
          Recent projects
        </h2>
        {/*<p className="grey-text w-responsive mx-auto mb-5">
          Duis aute irure dolor in reprehenderit in voluptate velit esse
        </p>*/}

        <MDBRow className="d-flex justify-content-center">
          

          <MDBCol md="6" xl="5" className="mb-4">
            <MDBCardBody className="pb-0  text-left" >
              <a href="https://i.imgur.com/sJYdqVG.gifv" target="__blank" className="blue-text">
                <h5 className="font-weight-bold mt-2 mb-3">
                  <MDBIcon fas icon="chart-line" className="pr-2" />
                  Job dispatch mobile app
                </h5>
              </a>
              {/*<h4 className="font-weight-bold mb-3">Plant stock deliver management</h4>*/}
              <p>
              This is a internal business APP to dispatch jobs from a desktop to a mobile device.
The mobile device user needs to be able to complete forms and send pictures via the app back to the desktop computer
both systems need to work hand to hand and have live monitoring
              </p>
              <MDBView className="overlay rounded z-depth-2" waves>
              <img
                src={require("./Projects/task-hub-business-app2.jpg")}
                alt=""
                className="img-fluid"
              />
                <MDBMask overlay="white-slight" />
            </MDBView>
            <br/>
              <a href="https://i.imgur.com/sJYdqVG.gifv" target="__blank"><MDBBtn color="blue" rounded size="sm">View Demo</MDBBtn></a>
            </MDBCardBody>

          </MDBCol>


          <MDBCol md="6" xl="5" className="mb-4">
            <MDBCardBody className="pb-0  text-left" >
              <a href="https://i.imgur.com/Bv4c6ls.gifv" target="__blank" className="green-text">
                <h5 className="font-weight-bold mt-2 mb-3">
                  <MDBIcon fas icon="chart-line" className="pr-2" />
                  Plant stock deliver management
                </h5>
              </a>
              {/*<h4 className="font-weight-bold mb-3">Plant stock deliver management</h4>*/}
              <p>
              This project is internal business stock delivery management software, including main modules of Supplier/Product management, Plant/Stock management, Truck/Driver management, and the Delivery Order auto-schedule per Plant/Stock latest status.
              </p>
              <p>
                The most complex part is the scheduling of each stock in different plants is calculated from values of other stocks.
                </p>
              <p>
                There are multiple roles that can access different part of th software. The driver has only access to their delivery order and can update the order status, which then can be monitered by admin. The plant Manager can update the latest stock quantity. The Admin can manage Supplier/Product and Truck/Driver. 
              </p>
              <MDBView className="overlay rounded z-depth-2" waves>
              <img
                src={require("./Projects/deliver-management.png")}
                alt=""
                className="img-fluid"
              />
                <MDBMask overlay="white-slight" />
            </MDBView>
            <br/><a href="https://i.imgur.com/Bv4c6ls.gifv" target="__blank"><MDBBtn color="blue" rounded size="sm">View Demo</MDBBtn></a>
            </MDBCardBody>

          </MDBCol>

          <MDBCol md="6" xl="5" className="mb-4">
            <MDBCardBody className="pb-0  text-left" >
              <a href="https://i.imgur.com/ID0bwvo.gifv" target="__blank" className="blue-text">
                <h5 className="font-weight-bold mt-2 mb-3">
                  <MDBIcon fas icon="chart-line" className="pr-2" />
                  P2P electronics online market place
                </h5>
              </a>
              {/*<h4 className="font-weight-bold mb-3">Plant stock deliver management</h4>*/}
              <p>
              A P2P online marketplace for electronics, via IOS, Android and website, which is basically an online marketplace except for strictly electronic devices. The role of the platform is to set the starndard attributes for new product type, and approve product listing. The system included following ecommernce modules: Store/Category/Product/Inventory management, Order/Fulfilment/Payment/Delivery management.
              </p>
              <p>
                  The most challending part is the user defined product attributes with schema definition, which needs to be searchable by shoppers. 
              </p>
              <MDBView className="overlay rounded z-depth-2" waves>
              <img
                src={require("./Projects/SecondHandWorld.png")}
                alt=""
                className="img-fluid"
              />
                <MDBMask overlay="white-slight" />
            </MDBView>
            <br/><a href="https://i.imgur.com/ID0bwvo.gifv" target="__blank"><MDBBtn color="blue" rounded size="sm">View Demo</MDBBtn></a>
            </MDBCardBody>

          </MDBCol>

          <MDBCol md="6" xl="5" className="mb-4">
            <MDBCardBody className="pb-0  text-left" >
              <a href="https://i.imgur.com/ElOSAAH.gifv" target="__blank" className="blue-text">
                <h5 className="font-weight-bold mt-2 mb-3">
                  <MDBIcon fas icon="chart-line" className="pr-2" />
                  Wechat h5/mini app: online ecommerce
                </h5>
              </a>
              {/*<h4 className="font-weight-bold mb-3">Plant stock deliver management</h4>*/}
              <p>
              Wechat H5/mini App: online ecommerce APP used by both customer and restaurant admin. It included following ecommernce modules: Store/Category/Product/Inventory management, Order/Fulfilment/Payment/Delivery management.
              </p>
              <MDBView className="overlay rounded z-depth-2" waves>
              <img
                src={require("./Projects/shitianxia.jpg")}
                alt=""
                className="img-fluid"
              />
                <MDBMask overlay="white-slight" />
            </MDBView>
            <br/><a href="https://i.imgur.com/ElOSAAH.gifv" target="__blank"><MDBBtn color="blue" rounded size="sm">View Demo</MDBBtn></a> 
            </MDBCardBody>

          </MDBCol>

          <MDBCol md="6" xl="5" className="mb-4">
            <MDBCardBody className="pb-0  text-left" >
              <a href="https://i.imgur.com/pdRErR7.gifv" target="__blank" className="blue-text">
                <h5 className="font-weight-bold mt-2 mb-3">
                  <MDBIcon fas icon="chart-line" className="pr-2" />
                  B2C Native Mobile APP
                </h5>
              </a>
              {/*<h4 className="font-weight-bold mb-3">Plant stock deliver management</h4>*/}
              <p>
              Native Mobile APP for Click/Collect/HomeDelivery online ecommerce. 
              </p>
              <MDBView className="overlay rounded z-depth-2" waves>
              <img
                src="https://i.imgur.com/pdRErR7.gif"
                alt=""
                className="img-fluid"
              />
                <MDBMask overlay="white-slight" />
            </MDBView>
            <br/><a href="https://i.imgur.com/pdRErR7.gifv" target="__blank"><MDBBtn color="blue" rounded size="sm">View Demo</MDBBtn></a>
              </MDBCardBody>

          </MDBCol>

          <MDBCol md="6" xl="5" className="mb-4">
            <MDBCardBody className="pb-0  text-left" >
              <a href="https://i.imgur.com/fxzDC5Q.gifv" target="__blank" className="blue-text">
                <h5 className="font-weight-bold mt-2 mb-3">
                  <MDBIcon fas icon="chart-line" className="pr-2" />
                  B2C Responsive Web APP
                </h5>
              </a>
              {/*<h4 className="font-weight-bold mb-3">Plant stock deliver management</h4>*/}
              <p>
              Responsive Web APP for Click/Collect/HomeDelivery online ecommerce. 
              </p>
              <MDBView className="overlay rounded z-depth-2" waves>
              <img
                src="https://i.imgur.com/fxzDC5Q.gif"
                alt=""
                className="img-fluid"
              />
                <MDBMask overlay="white-slight" />
            </MDBView>
            <br/><a href="https://i.imgur.com/fxzDC5Q.gifv" target="__blank"><MDBBtn color="blue" rounded size="sm">View Demo</MDBBtn></a>
            </MDBCardBody>

          </MDBCol>
          
          <MDBCol md="6" xl="5" className="mb-4">
            <MDBCardBody className="pb-0  text-left" >
              <a href="https://i.imgur.com/a1lbM1x.gifv" target="__blank" className="blue-text">
                <h5 className="font-weight-bold mt-2 mb-3">
                  <MDBIcon fas icon="chart-line" className="pr-2" />
                  Static website
                </h5>
              </a>
              {/*<h4 className="font-weight-bold mb-3">Plant stock deliver management</h4>*/}
              <p>
              Static website for infinitelearninginstitute.nsw.edu.au
              </p>
              <MDBView className="overlay rounded z-depth-2" waves>
              <img
                src={require("./Projects/website-infinitelearninginstitute_nsw_edu_au.jpg")}
                alt=""
                className="img-fluid"
              />
                <MDBMask overlay="white-slight" />
            </MDBView>
            <br/><a href="https://i.imgur.com/a1lbM1x.gifv" target="__blank"><MDBBtn color="blue" rounded size="sm">View Demo</MDBBtn></a>
            </MDBCardBody>

          </MDBCol>

        </MDBRow>
      </section>
          </MDBContainer>
          <MDBFooter className="pt-5 mt-4 text-center text-md-left grey">
            <Copyrights/>
          </MDBFooter>
        </div>
    )
  }
}


export default windowSize(Screen);