import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBNavbarNav, MDBNavItem, MDBNavLink } from 'mdbreact';
import {
  i18n,
} from 'web-translate';

const Copyrights = (props) => {
  const date = new Date().getFullYear()
  return (<>
  <MDBContainer>
            <MDBRow>
              <MDBCol md="6">
              <MDBNavbarNav>
                  <MDBNavItem>
                    <MDBNavLink to="/projects">{i18n("Show Cases")}</MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav>
                <h6 className="text-uppercase font-weight-bold"></h6>
                <ul className="list-unstyled">
                  <li><a href="#!">contact@sesameopen.com.au</a></li>
                </ul>
                <p>Syndey NSW 2000, Australia</p>
              </MDBCol>
              <MDBCol md="3">
                <MDBNavbarNav>
                  <MDBNavItem>
                    <MDBNavLink to="/onboard">{i18n("Request Prototype")}</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="/pricing">{i18n("Services")}</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="/">{i18n("Home")}</MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav>
                
              </MDBCol>
              <MDBCol md="3">
                <MDBNavbarNav>
                  <MDBNavItem>
                    <MDBNavLink to="/faq">{i18n("FAQs")}</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="/T&C">{i18n("Terms and Condition")}</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="/Privacy_Policy">{i18n("Privacy Policy")}</MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCol>
            </MDBRow>
            </MDBContainer>
    <MDBFooter className={props.className} style={{...props.style, zIndex: 2}}>
      <p className="footer-copyright mb-0 py-3 text-center">
        &copy; {date} Copyright: <a href={process.env.REACT_APP_HOSTNAME}>{process.env.REACT_APP_HOSTNAME}.</a>
      </p>
    </MDBFooter>
    </>
  )
}
export default Copyrights