import React from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBFormInline,
  MDBRow,
  MDBCol,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBMask,
  MDBIcon,
  MDBView,
  MDBBtn,
  MDBFooter,
} from 'mdbreact';
import Copyrights from '../../../Footer';
import { getHandler } from '../../../../uplift/actions';
import "./FAQ.css";
import NavBar from "./navbar";

import {
  i18n,
} from 'web-translate';



export default class Screen extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      collapseID: "",
      photoIndex: 0,
      isOpen: false
    };
  }

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));

    render() {
      return (
        <div id="posts">
            <NavBar parent={this}  options={{transparent:true}}/>
              <section id="home">
                <MDBView>
                  <MDBMask
                    className="d-flex justify-content-center align-items-center"
                    overlay="black-light"
                  >
                    <MDBContainer className="h-100 d-flex justify-content-center align-items-center">
                      <MDBRow>
                        <MDBCol md="12" className="mt-5 mx-auto text-center">
                          <div className="text-center">
                            <h5 className="h5-reponsive white-text text-uppercase font-weight-bold mb-3"><strong>{i18n("Frequent Questions")}</strong></h5>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </MDBMask>
                </MDBView>
              </section>
              <MDBContainer>
                <section id="older" className="mt-5 section extra-margins text-center">
                  <MDBRow>
                      <div className="col-lg-12 mb-4">
                          <h4 className="mb-4"><strong>{i18n("How are we sure that your platform is better than others?")}</strong></h4>
                          <p className="text-left small">{i18n("We are extremely confident of our technology and practice. To make you confident with our capability, we provide promotional prototype per your requirement for free. ")}<a className="d-inline" onClick={()=>getHandler()([{type:"GOTO",props:{path:"/onboard"}}])}><strong><u>{i18n("Start Prototype for Free, Limited-Time Only")}</u></strong></a></p>
                      </div>
                  </MDBRow>
                  <MDBRow>
                      <div className="col-lg-12 mb-4">
                          <h4 className="mb-4"><strong>{i18n("Why data-driven approach is so important?")}</strong></h4>
                          <p className="text-left small">{i18n("Even for a purely static marketing website, it is still a presentation of your data, just that the data is implicitly defined in your page structure (e.g. homepage has four children, being introduction, products, advantage, contact etc). Implicit is evil as it creates confusion and misunderstanding, instead we recommend the explicit way of data-first approach to not mix the data with the look. Making the data as single source of truth, we start from it and finalize it as much as possible and only then deal with the more detailed level of how it will look like, we save a lot of back-and-forth revision due to the change of the data change. For data-intensive App, the data structure is even more important as the root of everything. With recognizing this, we can save a lot of time in back and forth revision.")}</p>
                      </div>
                  </MDBRow>
                  <MDBRow>
                      <div className="col-lg-12 mb-4">
                          <h4 className="mb-4"><strong>{i18n("Why screen design is important?")}</strong></h4>
                          <p className="text-left small">{i18n("So for a static website there is no domain models required to be provided, but your data structure is actually implicitly written inside the screen designs. When designers work with you to refine the screens, this process itself is the quickest way to refine the requirement via \"what you see is what you get\". We should always try to finalize requirement during the design period instead of in the coding stage simply because the latter requires more effort to change than the screen design. You may say that your application is simple and do not need a dedicated designer. We understand that, but in that case, it is still recommended to have a low-resolution draft design (simple like ")}<a href="https://cdn.dribbble.com/users/17166/screenshots/4123178/image.png" target="_blank">{i18n("here")}</a>){i18n(" to help you refine your requirement (where to show what, how does user navigate back and forth between each of them?), before our developer code them. Without this step, you are essentially asking developer to do the brain storming for you, which is deemed to require a lot of changes later once you see it. So why not do it earlier?")}</p>
                      </div>
                  </MDBRow>
                  <MDBRow>
                      <div className="col-lg-12 mb-4">
                          <h4 className="mb-4"><strong>{i18n("How do we work together?")}</strong></h4>
                          <p className="text-left small">{i18n("We believe in agile iteration (as for any product, it is always evolving) and recognize that refinement of requirement itself is by no means straight-forward, but a process of evolving/iteration. Firstly, client starts the onboarding process described ")}<a className="d-inline" onClick={()=>getHandler()([{type:"GOTO",props:{path:"/onboard"}}])}><strong><u>{i18n("here")}</u></strong></a>. {i18n("Once service agreement confirmed, we build and deliver on a usual agile iteration basis, according to your requirement and priorities.")}</p>
                      </div>
                  </MDBRow>
                  <MDBRow>
                      <div className="col-lg-12 mb-4">
                          <h4 className="mb-4"><strong>{i18n("Do you do onsite development as part of in-house dev team?")}</strong></h4>
                          <p className="text-left small">{i18n("No, we don't usually send developer and work onsite with your dev team. For communications we heavily rely on online workspace (Surely we do onsite initial requirement meeting). Instead we deliver end-to-end product and solution including hosting. Because of that there is minimized inter-team dependency, and you are only responsible for providing domain specific requirement (as we are technical experts but not your domain expert, nor do we try to be).")}</p>
                      </div>
                  </MDBRow>
                  <MDBRow>
                      <div className="col-lg-12 mb-4">
                          <h4 className="mb-4"><strong>{i18n("Can you do enhancement on client's existing project?")}</strong></h4>
                          <p className="text-left small">{i18n("Yes and no. Yes, we can enhance but not via code-level, but via rebuilding an independent application optionally integrated with your existing project. We know there are many projects out there that do not meet the evolving requirement but we don't prioritize code-level enhancement of these projects (partially because we believe it is harder to enhance them, given the tech debt within them, than rebuild it with our cutting-edge technology and best practice).")}</p>
                      </div>
                  </MDBRow>
                  <MDBRow>
                      <div className="col-lg-12 mb-4">
                          <h4 className="mb-4"><strong>{i18n("How does the pricing work?")}</strong></h4>
                          <p className="text-left small">{i18n("For hosting related service, we charge subscription fee. For labour intensive work, we charge normally as hourly rate. See details ")}<a className="d-inline" onClick={()=>getHandler()([{type:"GOTO",props:{path:"/pricing"}}])}><strong><u>{i18n("here")}</u></strong></a>.</p>
                      </div>
                  </MDBRow>
                  <MDBRow>
                      <div className="col-lg-12 mb-4">
                          <h4 className="mb-4"><strong>{i18n("What cloud provider do you host on?")}</strong></h4>
                          <p className="text-left small">{i18n("Our technology is bound to a particular cloud provider, so there is no restriction on cloud provider")}</p>
                      </div>
                  </MDBRow>
              </section>

            </MDBContainer>

            <MDBFooter className="pt-5 mt-4 text-center text-md-left grey">
            <Copyrights/>
            </MDBFooter>
        </div>
    )
  }
}

