import React from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBFormInline,
  MDBRow,
  MDBCol,
  MDBAvatar,
  MDBMask,
  MDBIcon,
  MDBView,
  MDBBtn,
  MDBCardBody,
  MDBInput,
  MDBFooter,
  MDBCard,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
} from 'mdbreact';
import Lightbox from "react-image-lightbox";
import "./About.css";
import Copyrights from '../../../Footer';
import mobileDesign from "../../../../assets/img/BankAppLgScreenshot.png";
import mobileApp from "../../../../assets/img/BankApp.gif";
import webDesign from "../../../../assets/img/GameWebDesign.png";
import webBrowser from "../../../../assets/img/GameWeb.gif";
import webPhone from "../../../../assets/img/GameWeb_phone.gif";
import useCase from "../../../../assets/img/DescribeUseCase.gif";
import apiDemo from "../../../../assets/img/dataApi2.png";
import dataModel from "../../../../assets/img/model.png";
import idp from "../../../../assets/img/IDP.gif";
import br from "../../../../assets/img/businessReport.gif";
import YouTube from '@u-wave/react-youtube';
import windowSize from 'react-window-size';
import ModalImage from "react-modal-image";

import { Container, Button, lightColors, darkColors } from 'react-floating-action-button'
import { getHandler } from '../../../../uplift/actions';
import NavBar from "./navbar";

import {
  i18n,
} from 'web-translate';

//import Player from "react-background-video-player"; // breaks onsome browser
import ReactPlayer from "react-player";

class About extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      collapseID: "",
      photoIndex: 0,
      isOpen: false
    };
  }

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));

    render() {
      const { photoIndex, isOpen } = this.state;

  return (
    <div id="about">
        <NavBar parent={this}  options={{transparent:true}}/>
          <section id="home">
            <MDBView>
              <div style={{
                backgroundImage1: `url('header_poster.jpeg')`,
                backgroundSize1: "cover",
                backgroundRepeat1  : 'no-repeat',
                backgroundPosition: 'center',
                height: this.props.windowWidth/1.75,
                zIndex: (!this.state.videoReady)?-1:-2,
                //visibility: this.state.videoReady?"hidden":"visible"
                }}>
                  <ReactPlayer
              ref={this.ref}
              className='react-player'
              width={this.props.windowWidth}
              height={this.props.windowWidth/1.75}
              url={"header.mp4"}
              pip={false}
              playing={true}
              playsinline={true}
              light={'header_poster.jpeg'}
              loop={false}
              volume={0.2}
              muted={false}
              controls={true}
              onReady={() => {console.log('onReady');this.setState({videoReady:true})}}
              onStart={() => console.log('onStart')}

              onBuffer={() => console.log('onBuffer')}
              onSeek={e => console.log('onSeek', e)}
              onError={e => console.log('onError', e)}

              style={{
                //visibility: (!this.state.videoReady)?"hidden":"visible"
                  zIndex: (!this.state.videoReady)?-2:-1,
                }}
            />
                  {/*<video
                  width={this.props.width}
                  height={this.props.windowWidth/1.75}
                  style={{
                    //visibility: (!this.state.videoReady)?"hidden":"visible"
                      zIndex: (!this.state.videoReady)?-1:1,
                    }}
                  autoPlay muted loop id="myVideo"
                  src={"header.mp4"}
                  onCanPlay={()=>{console.log("ready");this.setState({videoReady:true})}}
                  />*/}
                  </div>
                {/*</div><MDBMask
                className="d-flex justify-content-center align-items-center"
                overlay="stylish-light"
              >
                <MDBContainer className="h-110 d-flex justify-content-center align-items-center">
                  <MDBRow>
                    <MDBCol md="12" className="mt-5 mx-auto text-center">
                    <h1 className="display-3 white-text mb-5"></h1>
                        
                        <h5 className="white-text mb-0 font-weight-bold" style={{fontSize: "14px"}}>{i18n("Why build software team when your core is not the software but the business model?")}</h5>
                        <h5 className="white-text mb-0 font-weight-bold">
                        {i18n("Streamline Application Build and Host")}</h5>
                        <h5 className="white-text mb-0 font-weight-bold" style={ç}>{i18n("Backend, Frontend, Web, APP")}</h5>
                        <MDBNavLink to="/onboard"><MDBBtn color="light-blue" size="sm">{i18n("Free Prototype, Limited-Time only")}</MDBBtn></MDBNavLink>
                        <MDBBtn color="indigo" size="lg">About <a href="!#" className="white-text font-weight-bold">US</a></MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
                </MDBMask>*/}
              
            </MDBView>
          </section>
          <Container styles={{
            left: "2%",
            zIndex: 1,
            width: "35px",
            bottom: "4%",
          }}>
              <Button
              tooltip="Free Prototype App, Limited-Time only"
              styles={{backgroundColor: darkColors.lightBlue, color: lightColors.white}}
              onClick={()=>getHandler()([{type:"GOTO",props:{path:"/onboard"}}])}
              ><h6 className="white-text"  style={{fontSize: "12px"}}>{i18n("Start Prototype")}</h6></Button>
          </Container>
          <section>
              {/*<div className="text-center view view-cascade">
                <YouTube
                  video="LoFPSsCias0"
                  width={this.props.windowWidth}
                  height={this.props.windowWidth/1.75}
                  autoplay={true}
                  controls={false}
                  showCaptions={false}
                  annotations={false}
                  modestBranding={true}
                  playsInline={true}
                  showRelatedVideos={false}
                  showInfo={false}
                />
              </div>*/}
              <MDBView>
              <MDBCol md="12" className="mt-5 mx-auto text-center">
            {/*<h5 className="text-center my-2 mx-4 h5">{i18n("We build, host, maintain and iterate your APP much more cost-effectively")}</h5>*/}
            <p className="my-2 font-weight-bold">{i18n("Enterprise application is HARD to build and maintain")}</p>
            <p className="my-2">{i18n("So why not focus on your real business comparative advantage")}</p>
            <p className="my-2">{i18n("Offload the heavy lift of managing your application to us")}</p>
            
            <p className="my-2 font-weight-bold">{i18n("Super-efficient Application Build and Host")}</p>
            <p className="my-2">{i18n("All industries, you provide requirement, we do the rest heavy lifting")}</p>
            <p className="my-2">{i18n("We build, integrate, host, monitoring, iteration")}</p>
            <p className="my-2">{i18n("Backend, Frontend, Web, APP")}</p>
            <MDBNavLink to="/onboard"><MDBBtn gradient="sunny-morning">{i18n("Free Prototype, Limited-Time only")}</MDBBtn></MDBNavLink>
              </MDBCol>
            {/*<p className="mx-4 mb-2">{i18n('Specialized in ')}<a className="d-inline" href="https://en.wikipedia.org/wiki/Create,_read,_update_and_delete" target="_blank"><strong>{i18n("CRUD based application")}</strong></a>{i18n(", any industry, per your requirement of complexity, customer-facing app or internal business application")}</p>
            <p className="mx-4 mb-2">{i18n('End-to-end solution, cloud hosting included, no requirement for in-house dev team')}</p>
            <p className="mx-4 mb-2">{i18n('You only need use/test the product and provide new requirement')}</p>
            <p className="mx-4">{i18n('Out-of-box')}: 
            &nbsp;<a className="d-inline" href="https://www.techopedia.com/definition/30455/cloud-infrastructure">Cloud infrastructure</a>, <a className="d-inline" href="https://en.wikipedia.org/wiki/Web_service">Web service</a>,
            &nbsp;<a className="d-inline" href="https://en.wikipedia.org/wiki/Identity_provider">{i18n('Identity management')}</a>, <a className="d-inline" href="https://en.wikipedia.org/wiki/OAuth">OAuth</a>, <a className="d-inline" href="https://en.wikipedia.org/wiki/Social_login">Social Login</a>,
            &nbsp;<a className="d-inline" href="https://en.wikipedia.org/wiki/Business_reporting">{i18n('Business reporting')}</a>, <a className="d-inline" href="https://en.wikipedia.org/wiki/Attribute-based_access_controln">Access control</a>, <a className="d-inline" href="https://en.wikipedia.org/wiki/Workflow_engine">Workflow engine</a>, <a className="d-inline" href="https://en.wikipedia.org/wiki/Scheduling_(computing)">Task scheduling</a>,
            &nbsp;<a className="d-inline" href="https://en.wikipedia.org/wiki/Microservices">Microservices</a>, <a className="d-inline" href="https://en.wikipedia.org/wiki/WebSocket">Real-time API</a>, <a className="d-inline" onClick={()=>getHandler()([{type:"GOTO",props:{path:"/pricing"}}])}><u><strong>{i18n('more')}...</strong></u></a></p>
          */}
            </MDBView>
          </section>
          <MDBContainer>
          {/*
          <section id="diagram">
          <h3 className="text-center my-5 h3">We are your IT development team, we build, host, maintain and iterate your APP much more cost-effectively</h3>

          <MDBRow>
          <MDBCol>
            <MDBCard wide>
              
              <div className="text-center view view-cascade"><img src={workflow}  alt="" className="img-fluid card-img-top" /></div>

            </MDBCard>
          </MDBCol>
          </MDBRow>
            </section>*/}
          {/* <section id="products" className="text-center">
              <h2 className="text-center my-5 h1">What we do</h2>
             <MDBRow>
                <div className="col-md-4 mb-4">
                    <MDBIcon fab icon="apple black-text" size="4x"/>
                    <h4 className="font-weight-bold my-4">iOs app</h4>
                </div>
                <div className="col-md-4 mb-4">
                    <MDBIcon fab icon="android green-text" size="4x"/>
                    <h4 className="font-weight-bold my-4">Android app</h4>
                </div>
                <div className="col-md-4 mb-4">
                    <MDBIcon icon="mobile-alt black-text" size="4x"/>
                    <h4 className="font-weight-bold my-4">Single page web app</h4>
                </div>
                <div className="col-md-4 mb-4">
                    <MDBIcon icon="database cyan-text" size="4x"/>
                    <h4 className="font-weight-bold my-4">Backend API</h4>
                </div>
                <div className="col-md-4 mb-4">
                    <MDBIcon icon="cloud cyan-text" size="4x"/>
                    <h4 className="font-weight-bold my-4">Cloud infrastructure</h4>
                </div>
                <div className="col-md-4 mb-4">
                    <MDBIcon fab icon="internet-explorer blue-text" size="4x"/>
                    <h4 className="font-weight-bold my-4">Website</h4>
                </div>
              </MDBRow>

            </section>*/}
            <hr className="my-2"/>

          <section className='text-center my-1'>
               <h2 className="h1-responsive font-weight-bold text-center my-5">
               {i18n("Our advantages")}
              </h2>
              <h3 className="h5-responsive font-weight-bold text-center my-5">
                1. {i18n('Your APP Design ➜ Mobile APP')}
              </h3>
              <MDBRow>
                <MDBCol md='8' className='md-0 mb-4'>
                  <MDBView className="overlay rounded z-depth-2 mx-2">
             
                    <ModalImage
                      small={mobileDesign}
                      large={mobileDesign}
                      alt={i18n("Input: Mobile designs")}
                    />

                  </MDBView>
                  <MDBCardBody className="pb-0">
                    <h5 className="font-weight-bold mb-3">{i18n("Your APP screen designs")}</h5>
                    <p className="text-center">
                      {i18n("You only need provide your screen design of any complexity (we are not generating APP by certain type of template, but instead we allow you to provide any kind of tailored design). Above is merely an example from ")}<a className="d-inline" href="https://sketchrepo.com/free-sketch/bank-app-concept-busines-freebie/" target="_blank"><u><strong>{i18n("here")}</strong></u></a>.
                    </p>
                  </MDBCardBody>
                </MDBCol>
                <MDBCol md='4' className='md-0 mb-4'>
                  <MDBView className="overlay rounded z-depth-2 text-center mx-2">
                    <ModalImage
                      small={mobileApp}
                      large={mobileApp}
                      alt={i18n("Output: Mobile App")}
                    />
                  </MDBView>
                  <MDBCardBody className="pb-0">
                    <h5 className="font-weight-bold mb-3">{i18n('We deliver real Mobile APP')}</h5>
                    <p className="text-center">
                    {i18n('We quickly turn the design into pixel-precision real APP.')}
                    </p>
                  </MDBCardBody>
                </MDBCol>
                </MDBRow>
                <h3 className="h5-responsive font-weight-bold text-center my-5">
                2. {i18n('Your Web design ➜ Website')}
              </h3>
              <MDBRow>
                <MDBCol md='8' className='md-0 mb-4'>
                  <MDBView className="overlay rounded z-depth-2 mx-2">
                    <ModalImage
                      small={webDesign}
                      large={webDesign}
                      alt={i18n("Input: Website Designs")}
                    />
                  </MDBView>
                  <MDBCardBody className="pb-0">
                    <h5 className="font-weight-bold mb-3">{i18n("Your APP screen designs")}</h5>
                    <p className="text-center">
                      {i18n("We only require your design. The above is an example from ")}<a className="d-inline" href="https://sketchrepo.com/free-sketch/gaming-dashboard-landing-pages-freebie/" target="_blank"><u><strong>{i18n("here")}</strong></u></a>.
                    </p>
                  </MDBCardBody>
                </MDBCol>
                <MDBCol md='4' className='md-0 mb-4'>
                  <MDBView className="overlay rounded z-depth-2 text-center mx-2">
                    <ModalImage
                      small={webPhone}
                      large={webPhone}
                      alt={i18n("Output: Responsive Phone Web App")}
                    />
                  </MDBView>
                  <MDBCardBody className="pb-0">
                    <h5 className="font-weight-bold mb-3"> {i18n('We deliver responsive Web APP')}</h5>
                    <p className="text-center">
                    {i18n('We quickly turn the design into pixel-precision Web APP.')}
                    </p>
                  </MDBCardBody>
                </MDBCol>
                <MDBCol md='12' className='md-0 mb-4'>
                  <MDBView className="overlay rounded z-depth-2 text-center mx-2">
                    <ModalImage
                      small={webBrowser}
                      large={webBrowser}
                      alt={i18n("Output: Responsive Website")}
                    />
                  </MDBView>
                  <MDBCardBody className="pb-0">
                    <h5 className="font-weight-bold mb-3"> {i18n('We deliver live Website')}</h5>
                    <p className="text-center">
                    {i18n('We turn the design into pixel-precision Website.')}
                    </p>
                  </MDBCardBody>
                </MDBCol>
                
                </MDBRow>
            
              <h3 className="h5-responsive font-weight-bold text-center my-5">
                3. {i18n('Your Use Case ➜ Backend API')}
              </h3>
              <MDBRow>
                <MDBCol md='5' className='md-0 mb-4'>
                  <MDBView className="overlay rounded z-depth-2 mx-2">
                    <ModalImage
                      small={useCase}
                      large={useCase}
                      alt={i18n("Input: Your Use Cases")}
                    />
                  </MDBView>
                  <MDBCardBody className="pb-0">
                    <h5 className="font-weight-bold mb-3"> {i18n("You tell us your high-level use cases")}</h5>
                    <p className="text-center">
                    {i18n('Simply describe your use case and we handle it from there. Want a free trial? Start from ')}<a className="d-inline" href="/onboard" target="_blank"><u><strong>{i18n("here")}</strong></u></a>.
                    </p>
                  </MDBCardBody>
                </MDBCol>
                <MDBCol md='7' className='md-0 mb-4'>
                  <MDBView className="overlay rounded z-depth-2 text-center mx-2">
                    <ModalImage
                      small={dataModel}
                      large={dataModel}
                      alt={i18n("Output: Data Models")}
                    />
                  </MDBView>
                  <MDBCardBody className="pb-0">
                    <h5 className="font-weight-bold mb-3">{i18n("We build your internal Data Models")}</h5>
                    <p className="text-center">
                    {i18n('We design your data structure to align with your business use cases, with maximized flexibility for future enhancement.')}
                    </p>
                  </MDBCardBody>
                </MDBCol>
                <MDBCol md='12' className='md-0 mb-4'>
                  <MDBView className="overlay rounded z-depth-2 text-center mx-2">
                    <ModalImage
                      small={apiDemo}
                      large={apiDemo}
                      alt={i18n("Output: Backend API")}
                    />
                  </MDBView>
                  <MDBCardBody className="pb-0">
                    <h5 className="font-weight-bold mb-3"> {i18n('We deliver live Backend API')}</h5>
                    <p className="text-center">
                    {i18n('We turn your use case into production ready backend API. Seamlessly integrated with OAuth, built-in highly customizable access control to secure user data of all kinds of requirement. Not only Http based API, but also real-time websocket API available for your particular need. Out-of-box workflow engine to orchestrate all kinds of integration to achieve your business process of any complexity. Advanced task scheduling module to handle your background jobs featured with delayed jobs, repeated cron jobs, retry, priority and automatic recover. Provide in-bound webservice or out-bound webhook interface to integrate with your existing system.')}
                    </p>
                  </MDBCardBody>
                </MDBCol>
                
                </MDBRow>
            
                <h3 className="h5-responsive font-weight-bold text-center my-5">
                4. {i18n('Out-of-box Identity Management')}
                </h3>
                <MDBRow>
                  <MDBCol md='12' className='md-0 mb-4'>
                    <MDBView className="overlay rounded z-depth-2 mx-2">
                      <ModalImage
                        small={idp}
                        large={idp}
                        alt={i18n("We provide out-of-box Identity Management")}
                      />
                    </MDBView>
                    <MDBCardBody className="pb-0">
                      <h5 className="font-weight-bold mb-3">{i18n("Out-of-box Identity Management")}</h5>
                      <p className="text-center">
                      {i18n('User registration, login/out, forgot, reset, external/internal sso, social login, OAuth')}
                      </p>
                    </MDBCardBody>
                  </MDBCol>
                  </MDBRow>

                  <h3 className="h5-responsive font-weight-bold text-center my-5">
                5. {i18n('Out-of-box Business Reporting')}
                </h3>
                <MDBRow>
                  <MDBCol md='12' className='md-0 mb-4'>
                    <MDBView className="overlay rounded z-depth-2 mx-2">
                      <ModalImage
                        small={br}
                        large={br}
                        alt={i18n("We provide out-of-box Business Reporting")}
                      />
                    </MDBView>
                    <MDBCardBody className="pb-0">
                      <p className="text-center">
                      {i18n("We provide hosted Business Reporting capability to visualize your data of all aspects, to accelerate your business's growth. Featured with complex role-based access control, highly customizable dashboard and many more")}
                      </p>
                    </MDBCardBody>
                  </MDBCol>
                  </MDBRow>
                  <h3 className="h5-responsive font-weight-bold text-center my-5">
                6. {i18n('Enterprise-ready Application')}
                </h3>
                <MDBRow>
                  <MDBCol md='12' className='md-0 mb-4'>
                    {/*<MDBView className="overlay rounded z-depth-2 mx-2">
                      <ModalImage
                        small={br}
                        large={br}
                        alt={i18n("We provide out-of-box Business Reporting")}
                      />
                    </MDBView>*/}
                    <MDBCardBody className="pb-0">
                      <p className="text-center">
                      {i18n("All functionalities are shipped with enterprise-grade supporting modules: Build-in access control of any complexity. Out-of-box security with rate-limiting, IP whitelisting, host validation etc. Reactive error monitoring across frontend and backend. Proactive monitoring of all system dependencies and integration points. Out-of-box auditing of system and user activities. Load balancing, failover and fault-tolerant.")}
                      </p>
                    </MDBCardBody>
                  </MDBCol>
                  </MDBRow>
            </section>
      
            <hr className="my-2"/>

            <section id="products" className="text-center">
              <h3 className="text-center my-5 h3">{i18n("Why Us?")}</h3>
              <p className="text-center mb-5 w-responsive mx-auto lead grey-text"></p>
              <MDBRow>
                <div className="col-md-4 mb-4">
                    <MDBIcon icon="draw-polygon pink-text" size="2x"/>
                    <h4 className="font-weight-bold my-4">{i18n("Efficiency")}</h4>
                    <p className="grey-text text-left">{i18n("We have structured approach to handle every small step of our engagement with you to ensure highest efficiency. We ensure minimum repetition during all lifecycles of the software engineering via better design, automation and processes. We ensure flexibility so that change is always relatively easy, and thus achieve best agility. All of these add up to contribute to our overall efficiency.")}</p>
                </div>
                <div className="col-md-4 mb-4">
                    <MDBIcon icon="database indigo-text" size="2x"/>
                    <h4 className="font-weight-bold my-4">{i18n("Data driven modeling")}</h4>
                    <p className="grey-text text-left">{i18n("We believe your business models are the core to your IT product, and we always start from domain models of your business, ensure consistent core business data models and turn them into consumable API that can be directly used by your APPs.")}</p>
                </div>
                <div className="col-md-4 mb-4">
                    <MDBIcon far icon="chart-bar indigo-text" size="2x"/>
                    <h4 className="font-weight-bold my-4">{i18n("Build and host")}</h4>
                    <p className="grey-text text-left">{i18n("We support all lifecycle of an app, from building/iterating and operationally hosting an app, to business reporting. We do the heavy lifting to ensure your application is running at enterprise level environment including features such as ssl, scaling, loading-balancing, alerting, rate limiting, failover, fault-tolerance, zero-downtime upgrading and many more, very cost effectively.")}</p>
                </div>
                <div className="col-md-12 mb-12">
                    <MDBIcon icon="gift pink-text" size="2x"/>
                    <h4 className="font-weight-bold my-4">{i18n("Free prototype")}</h4>
                    <p className="grey-text text-left">{i18n("We are so confident of our technology and thus we provide you prototype per your special business requirement to decide if engage with us. ")}<a className="d-inline" onClick={()=>getHandler()([{type:"GOTO",props:{path:"/onboard"}}])}><strong><u className="amber-text">{i18n("Start now to get a free prototype, limited-time only")}</u></strong></a></p>
                </div>
              </MDBRow>
              <MDBRow>

                <div className="col-md-4 mb-4">
                    <MDBIcon icon="users indigo-text" size="2x"/>
                    <h4 className="font-weight-bold my-4">{i18n("Long-term tech team")}</h4>
                    <p className="grey-text text-left">{i18n("Because we cover solution design, frontend web, iOs/android, backend, devops, having us is equal to have a ready-to-go tech team for your product. And by doing so, we remove all the frictions of cross-team communication, disrupt in methodology and in the end failure of your app.")}</p>
                </div>
                <div className="col-md-4 mb-4">
                    <MDBIcon fab icon="slack-hash indigo-text" size="2x"/>
                    <h4 className="font-weight-bold my-4">{i18n("Responsive communications")}</h4>
                    <p className="grey-text text-left">{i18n("We believe communication clearness is very important. We practice online working space for ad-hoc communications, and we provide all the necessary systems to streamline the development, maintenance and supporting flow.")}</p>
                </div>
                <div className="col-md-4 mb-4">
                    <MDBIcon icon="dollar-sign pink-text" size="2x"/>
                    <h4 className="font-weight-bold my-4">{i18n("Cost-effective")}</h4>
                    <p className="grey-text text-left">{i18n("While maintaining best design/technology for your future changes, the cost of building an app is significantly lower as compared to conventional consulting. Many of our services are free. We also host your app with monitoring and support, and continue to evolve your product.")}</p>
                </div>
 
              </MDBRow>
            </section>

            <hr className="my-2"/>

            <section className="section my-5wow fadeIn" data-wow-delay="0.3s">

            <h3 className="text-center my-5 h3">{i18n("Who are the audience?")}</h3>
            {/*<p className="text-center mb-5 w-responsive mx-auto">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus veniam.</p>*/}
            <MDBRow>
                <MDBCol md="6">
                    <MDBRow className="mb-2">
                        <MDBCol size="2">
                          <MDBIcon size="2x" icon="business-time purple-text"/>
                        </MDBCol>
                        <MDBCol size="10">
                            <h5 className="font-weight-bold my-4">{i18n("Need quicker-to-market")}</h5>
                            <p className="grey-text text-left">{i18n("Business wanting best agility from the tech team to support the market change.")}</p>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="mb-2">
                        <MDBCol size="2">
                          <MDBIcon size="2x" icon="dollar-sign indigo-text"/>
                        </MDBCol>
                        <MDBCol size="10">
                            <h5 className="font-weight-bold my-4">{i18n("Want lower budget")}</h5>
                            <p className="grey-text text-left">{i18n("Business wanting customized digital product but with limited resource to support a dev team (web, app, backend, devops developer).")}</p>
                        </MDBCol>
                    </MDBRow>

                    

                </MDBCol>
                <MDBCol md="6">
                  <MDBRow className="mb-2">
                        <MDBCol size="2">
                            <MDBIcon size="2x" icon="users blue-text"/>
                        </MDBCol>
                        <MDBCol size="10">
                            <h5 className="font-weight-bold my-4">{i18n("Need extra develop team")}</h5>
                            <p className="grey-text text-left">{i18n("Business with dev team but does not have sufficient capability for new feature requirement.")}</p>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="mb-2">
                        <MDBCol size="2">
                            <MDBIcon size="2x" icon="user-tie cyan-text"/>
                        </MDBCol>
                        <MDBCol size="10">
                            <h5 className="font-weight-bold my-4">{i18n("Agency")}</h5>
                            <p className="grey-text text-left">{i18n("Agency that may not have sufficient capability or capacity for building hosting client's project")}</p>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
            </section>
             
{/*
            <section id="work">
              <h1 className="text-center my-5 h1">Our work</h1>
              <p className="text-center mb-5 w-responsive mx-auto">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>

              <div className="mdb-lightbox">
            <MDBRow>
              <MDBCol md="4">
                <figure>
                  <img
                    src={smallImages[0]}
                    alt="The pretty one talks"
                    className="img-fluid"
                    onClick={() =>
                      this.setState({ photoIndex: 0, isOpen: true })
                    }
                  />
                </figure>
              </MDBCol>
              <MDBCol md="4">
                <figure>
                  <img
                    src={smallImages[1]}
                    alt="Our office looks like school"
                    className="img-fluid"
                    onClick={() =>
                      this.setState({ photoIndex: 1, isOpen: true })
                    }
                  />
                </figure>
              </MDBCol>
              <MDBCol md="4">
                <figure>
                  <img
                    src={smallImages[2]}
                    alt="Best gear does not equal best devs"
                    className="img-fluid"
                    onClick={() =>
                      this.setState({ photoIndex: 2, isOpen: true })
                    }
                  />
                </figure>
              </MDBCol>
            </MDBRow>
          </div>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              imageTitle={photoIndex + 1 + "/" + images.length}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length
                })
              }
            />
            )}

            </section>
            */}
            <hr className="my-2"/>
            <section id="contact">

            <MDBRow>
              <MDBCol md="12" className="mt-0 text-center">
                <MDBNavLink to="/onboard"><MDBBtn gradient="sunny-morning" size="lg">{i18n("Start Prototype for Free, Limited-Time Only")}</MDBBtn></MDBNavLink>
                {/*<MDBCardBody className="form">
                  <h3 className="mt-4">
                    <MDBIcon icon="envelope" className="pr-2" />
                    Write to us:
                  </h3>
                  <MDBRow>
                    <MDBCol md="6">
                      <div className="md-form mb-0">
                        <MDBInput
                          type="text"
                          id="form-contact-name"
                          label="Your name"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="md-form mb-0">
                        <MDBInput
                          type="text"
                          id="form-contact-email"
                          label="Your email"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="6">
                      <div className="md-form mb-0">
                        <MDBInput
                          type="text"
                          id="form-contact-phone"
                          label="Your phone"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="md-form mb-0">
                        <MDBInput
                          type="text"
                          id="form-contact-company"
                          label="Your company"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="md-form mb-0">
                        <MDBInput
                          type="textarea"
                          id="form-contact-message"
                          label="Your message"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
              </MDBCardBody>*/}
              </MDBCol>
              {/*<MDBCol lg="4">
                <MDBCardBody className="contact text-center h-100">
                  <h3 className="my-4 pb-2">Contact information</h3>
                  <ul className="text-lg-left list-unstyled ml-4">
                     <li>
                      <p>
                        <MDBIcon icon="phone" className="pr-2" />+ 01 234 567 89
                      </p>
                    </li>
                    <li>
                      <p>
                        <MDBIcon icon="envelope" className="pr-2" />
                        contact@sesameopen.com.au
                      </p>
                    </li>
                  </ul>
                  <hr className="hr-light my-4" />
                  <ul className="list-inline text-center list-unstyled">
                    <li className="list-inline-item">
                      <a href="#!" className="p-2 fa-lg w-ic">
                        <MDBIcon fab icon="twitter" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#!" className="p-2 fa-lg w-ic">
                        <MDBIcon fab icon="linkedin" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#!" className="p-2 fa-lg w-ic">
                        <MDBIcon fab icon="instagram" />
                      </a>
                    </li>
                  </ul>
                </MDBCardBody>
            </MDBCol>*/}
            </MDBRow>
          </section>

          </MDBContainer>
          <MDBFooter className="pt-5 mt-4 text-center text-md-left grey">
            <Copyrights/>
          </MDBFooter>
        </div>
    )
  }
}


export default windowSize(About);