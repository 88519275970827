
import React, { Component } from "react";

import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBListGroup,
    MDBListGroupItem,
    MDBMedia,
    MDBIcon,
    MDBView,
    MDBBadge
  } from 'mdbreact';

import {
Link,
} from 'react-router-dom';
  
import { getHandler } from "../../../uplift/actions";
import update from "immutability-helper";
const moment = require('moment-timezone');
const _ = require('lodash');


export default class Screen extends Component {
    constructor(props) {
        super(props);
        this.state={ Thread:[]};
    }
 

    componentDidMount(){
        console.log(this.props.match);
        getHandler()([{ 
            type: 'HTTP4',  
            props: { 
                noSpinner:false,
                method:'GET',
                path: '/dashboard/thread',
                }
            }], this.props.parent, this); 
    }

    submit = (form) => {

        if(form.errors.length==0){
        getHandler()([
            { type: 'HTTP4',  props: { onStart1: ()=>{ this.setState({loading:true});}, 
            onComplete: ()=>{ this.setState({submitting:false});}, noSpinner:false, 
            method:'PUT', path: "/dashboard/thread"+`${_.get(this.props.match, "params.id")?"?"+_.get(this.props.match, "params.id"):''}`,
            data: {form:  form.formData, } } }],this.props.parent);
        }
    }

    render() {
        return (
        <MDBContainer fluid>
    
            <section className="mb-5">
            <h5 className="my-5 dark-grey-text font-weight-bold">Thread</h5>
            <MDBListGroup className="z-depth-1">
                {this.state.Thread && this.state.Thread.map(t=>
                    <Link to={`/pages/workspace/thread/upsert/${t.id}`}>
                    <MDBListGroupItem id={t.id} tag="a" href="#!" hover className="flex-column align-items-start">
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="font-weight-bold mb-3 mt-2">{t.name}</h5>
                        <small>{moment(t.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</small>
                    </div>
                    <p className="mb-1">{t.description}</p>
                    <small>todo</small>
                    </MDBListGroupItem>
                 </Link>)
                }
            </MDBListGroup>
            </section>
            
        </MDBContainer>
        );
    }
}

