import React from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBTooltip,
  MDBBtn
} from 'mdbreact';
import LinkCard from '../LinkCard';

const Tooltips = () => {
  return (
    <div id="tooltips">
      <MDBContainer fluid style={{ paddingTop: '150px' }}>
        <MDBRow center>
          <MDBTooltip
            placement="top"
          >
            <MDBBtn color="primary">Tooltip on top</MDBBtn>
            <div>Tooltip on top</div>
          </MDBTooltip>
          <MDBTooltip
            placement="right"
          >
            <MDBBtn color="primary">Tooltip on right</MDBBtn>
            <div>Tooltip on right</div>
          </MDBTooltip>
          <MDBTooltip
            placement="bottom"
          >
            <MDBBtn color="primary">Tooltip on bottom</MDBBtn>
            <div>Tooltip on bottom</div>
          </MDBTooltip>
          <MDBTooltip
            placement="left"
          >
            <MDBBtn color="primary">Tooltip on left</MDBBtn>
            <div>Tooltip on left</div>
          </MDBTooltip>
        </MDBRow>
        <LinkCard docs="https://mdbootstrap.com/docs/react/advanced/tooltips/" />
      </MDBContainer>
    </div>
  );
}

export default Tooltips;