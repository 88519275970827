"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _iso = _interopRequireDefault(require("./data/iso"));

var _iso2 = _interopRequireDefault(require("./data/iso2"));

var _map = _interopRequireDefault(require("./data/map"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // Relevant ISO: ISO 639-1 & ISO 639-2. Google uses the ISO 639-1.
// Valid ISO 639-1 codes
// https://www.loc.gov/standards/iso639-2/php/code_list.php
// Extract these with this code (after loading https://superdom.site/ )
// [...dom.table[1].querySelectorAll('tbody tr')].slice(1).filter(row => !/^\s*$/.test(row.querySelector('td:nth-child(2)').textContent)).map((row, i) => `"${row.querySelector('td:nth-child(2)').textContent}", ${i % 12 === 11 ? '\n' : ''}`).join('');
// Parsed from here: https://github.com/wooorm/iso-639-2/blob/master/index.json
// Extract these with this code (after loading https://superdom.site/ ) + a lot of manual clean up
// [...dom.table[1].querySelectorAll('tbody tr')].slice(1).filter(row => !/^\s*$/.test(row.querySelector('td:nth-child(2)').textContent)).map(row =>
//   `  "${row.querySelector('td:nth-child(3)').textContent.toLowerCase()}": "${row.querySelector('td:nth-child(2)').textContent.toLowerCase()}",`
// ).join('\n');
// Language parser
//   @name: a string to be parsed
//   @output: the normalized language string


var _default = function _default(name) {
  // Validate the name string
  if (typeof name !== 'string') {
    throw new Error("The language must be a string, received ".concat(typeof name));
  } // Possible overflow errors


  if (name.length > 100) {
    throw new Error("The language must be a string under 100 characters, received ".concat(name.length));
  } // Let's work with lowercase for everything


  name = name.toLowerCase(); // Pass it through several possible maps to try to find the right one

  name = _map.default[name] || _iso2.default[name] || name; // Make sure we have the correct name or throw

  if (!_iso.default.includes(name)) {
    throw new Error("The name \"".concat(name, "\" is not part of the ISO 639-1 and we couldn't automatically parse it :("));
  }

  return name;
};

exports.default = _default;