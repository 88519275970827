"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addTranslation = addTranslation;
exports.getDefaultLanguage = getDefaultLanguage;
exports.getJson = getJson;
exports.i18n = i18n;
exports.setLanguage = setLanguage;
exports.setUrlPrefix = setUrlPrefix;
Object.defineProperty(exports, "translate", {
  enumerable: true,
  get: function get() {
    return _translate.translate;
  }
});
exports.haveTranslations = exports.getSupportedLanguages = exports.getLanguageCode = void 0;

require("whatwg-fetch");

var _translate = require("./translate");

var languageCode;
var translations = {};
var urlPrefix = '/';

function addTranslation(key, value) {
  translations[key] = value;
}

function getDefaultLanguage() {
  if (typeof navigator === 'undefined') return 'en';
  var langCountry = navigator.languages ? navigator.languages[0] : navigator.language;
  return langCountry ? langCountry.split('-')[0] : 'en';
}

function getJson(urlSuffix) {
  if (!window.fetch) return Promise.resolve({}); // in tests

  var url = urlPrefix + urlSuffix;
  return window.fetch(url).then(function (res) {
    return res.json();
  }).catch(function (e) {
    console.error('web-translate getJson error:', e.message, '; url =', url);
    return {};
  });
}

var getLanguageCode = function getLanguageCode() {
  return languageCode;
};

exports.getLanguageCode = getLanguageCode;

var getSupportedLanguages = function getSupportedLanguages() {
  return getJson('languages.json');
};

exports.getSupportedLanguages = getSupportedLanguages;

var haveTranslations = function haveTranslations() {
  return Object.keys(translations).length > 0;
};

exports.haveTranslations = haveTranslations;

function i18n(key, data) {
  var t = translations[key] || key;
  if (!data) return t; // Could use the Lodash template function for this.
  //_.templateSettings.interpolate = /\${(\w+)}/g;
  //const compiled = _.template(t);
  //return compiled(data);

  for (var _i = 0, _Object$keys = Object.keys(data); _i < _Object$keys.length; _i++) {
    var _key = _Object$keys[_i];
    var value = data[_key];
    var re = new RegExp('\\$\\{' + _key + '\\}', 'g');
    t = t.replace(re, value);
  }

  return t;
}

function setLanguage(code) {
  languageCode = code;
  return getJson(code + '.json').then(function (t) {
    return translations = t;
  }).catch(function (e) {
    return console.error('web-translate setLanguage error:', e.message);
  });
}

function setUrlPrefix(up) {
  urlPrefix = up;
}

setLanguage(getDefaultLanguage());