import React from 'react';


export default class Screen extends React.Component {
    constructor(props) {
        super(props);
        this.state={ };
    }
 
    componentDidMount(){
        this.props.parent.setState({showLoader: true});
    }
    
    render() {
        return <></>;
    }
}
