import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';

import { getProps} from "./utilities/property";
import { getHandler } from "./actions";


import { toast, ToastContainer, MDBSpinner } from 'mdbreact';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';


import {  Store } from './AppContext';


/*
 * Component Definitions
 */

export default class PageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      loadMore: false,
      sizePerPage: props.sizePerPage?props.sizePerPage:1,
      page: props.page? props.page:1,
    };

    if (!this.props.components) {
      // this.loadPage();
    }
    this.handleScroll = this.handleScroll.bind(this);
  }






  loadPage = () => {


    axios.post('http://localhost:3000/api/v1/route', {
      route: "/"
    })
      .then(function (response) {
        // handle success
        console.log(response);

        this.setState({ components: response.data[0].props.components });
      }.bind(this))
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }


  onRefresh = () => {
    if (this.props.refreshURI && this.state.refreshing === false) {
      this.setState({
        refreshing: true,
      });
      /*this.props.dispatch(appActions.handleAction({
        type: 'GET',
        props: {
          uri: this.props.refreshURI,
        },
      }, {
        navigator: this.props.navigator,
      })).then(() => {
        this.setState({
          refreshing: false,
        });
      });*/
    }
  }
  componentDidMount() {
     // if(this.props.loadMoreURI) this component can be mounted before loading the components from remote
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    //if(this.props.loadMoreURI) 
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if(!this.props.loadMoreURI) return;
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= (docHeight - 10)) { //rounding
      //alert(`windowBottom: ${windowBottom} -- docHeight: ${docHeight}`);
      console.log({
        message:'bottom reached'
      });
      this.onLoadMore();
    } else {
      console.log({
        message:'not at bottom'
      });
    }
  }

  onLoadMore = async () => {
    if (this.props.loadMoreURI 
      //&& !this.onEndReachedCalledDuringMomentum 
      && this.state.loadMore === false) {
      // Show loading indicator
      this.setState({
        loadMore: true,
      });
      this.forceUpdate();

      // Load more

      await getHandler()([{
        type: 'GET',
        props: {
          path: `${this.props.loadMoreURI}?page=${this.state.page+1}&sizePerPage=${this.state.sizePerPage}`,
        },
      }])
      /*this.props.dispatch(appActions.handleAction({
        type: 'GET',
        props: {
          uri: this.props.loadMoreURI,
        },
      }, {
        navigator: this.props.navigator,
      })).then(() => {
        // Hide load indicator
        this.setState({
          loadMore: false,
        });
        this.onEndReachedCalledDuringMomentum = true;
      });*/
      this.setState({
        loadMore: false,
        page: this.state.page+1,
      });
    }
  }

  renderFooter = () => {
    return (
      <footer></footer>
    );
  }

  renderHeader = () => {
    return (
      <header></header>
    );
  }

  componentDidUpdate(prevProps) {
    console.log("PageContainer.componentDidUpdate", this.props, prevProps);
    if(prevProps.path!=null &&  prevProps.path != this.props.path 
        // not root path
        && !((prevProps.path=="" && this.props.path=="/")
        ||(prevProps.path=="/" && this.props.path==""))
        ) 
        {
        console.log("RELOAD PAGE");
        let filteredPath = this.props.path;
        const basePath = getProps().basePath;
        if(filteredPath.startsWith(basePath)){
            filteredPath = filteredPath.substring(basePath.length);
        }
        this.props.handleActions([{type:"GET", props:{path:filteredPath}}])
      }else  {
        console.log("no need RELOAD PAGE");
        //this.props.handleActions([{type:"OPEN_PAGE", props:{path:this.props.path}}])
      }
    
  }


  startUploadV2(opts, onComplete, onStart, onProgress, onError){
    console.log("opts=========", opts);
    //console.log(Upload.getFileInfo);
    const metadata = {};
    {
      console.log("metadata===",metadata);
      const options = Object.assign({
        method: 'POST',
        headers: {
          //'content-type': metadata.mimeType, // server requires a content-type header
          'Authorization': `Bearer ${Store.getState().credential?Store.getState().credential.accessToken:''}`
        }
      }, opts)

      const data = new FormData();
      //data.append('name', this.state.selectedImage.fileName); // you can append anyone.
      data.append(opts.field, {
          uri: opts.uri,
          type: metadata.mimeType?metadata.mimeType:'image/jpeg', // or photo.type
          name: opts.field,
      });
      if (onStart) onStart(options);
      console.log(options)
      axios.post(opts.url, data, options).then(res => {
          console.log(res)
          if (onComplete) onComplete(res);
            return;
        }).catch(e=>{
          console.log(e);
          if (onError) onError(e); 
        });
    }
  }
  openModal = (header, body, onOk, onCancel) => {
    this.setState({
      mdbModal:true,
      mdbOnOk: onOk,
      mdbOnCancel: onCancel,
      mdbModal_header: header,
      mdbModal_body: body
    });
  }
  cancelModal = () =>{
    this.state.mdbOnCancel();
    this.setState({mdbModal:!this.state.mdbModal});
  }
  okModal = () =>{
    this.state.mdbOnOk();
    this.setState({mdbModal:!this.state.mdbModal});
  }

  render() {
    console.log("PageContainer.render", this.state, this.props);
    if (true) {
      return (
        <div>
          <ToastContainer
          hideProgressBar={true}
          newestOnTop={true}
          autoClose={5000}
        />
        <MDBContainer>
        <MDBModal isOpen={this.state.mdbModal} toggle={this.cancelModal} centered>
          <MDBModalHeader toggle={this.cancelModal}>{this.state.mdbModal_header}</MDBModalHeader>
          <MDBModalBody>
           {this.state.mdbModal_body}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.cancelModal}>Cancel</MDBBtn>
            <MDBBtn color="primary" onClick={this.okModal}>Ok</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        </MDBContainer>

        {this.props.screen && <this.props.screen {...this.props} parent={this} />}
        {this.state.showLoader && <div style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          "WebkitTransform": "translate(-50%, -50%)",
          transform: "translate(-50%, -50%)",
      }}> <br></br> <MDBSpinner  black big /> <br></br><br></br></div>}
        </div>
      );
    } else {
      return (
        <div style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          "WebkitTransform": "translate(-50%, -50%)",
          transform: "translate(-50%, -50%)",
      }}><MDBSpinner  black big /></div>
      );
    }
  }
}
/*
{
          position: "absolute",
          top: "50%",
          left: "50%"}
          */