"use strict";

var _slicedToArray = require("/Users/s114279/work/dev/own/appbuilder/mdb-react-admin-pro/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // Translation engines
// Handle different translations differently to allow for extra flexibility

var google = {
  needkey: true,
  fetch: function fetch(_ref) {
    var key = _ref.key,
        from = _ref.from,
        to = _ref.to,
        text = _ref.text;
    return ["https://translation.googleapis.com/language/translate/v2?key=".concat(key, "&source=").concat(from, "&target=").concat(to, "&q=").concat(encodeURIComponent(text)), {
      method: 'POST'
    }];
  },
  parse: function parse(res) {
    return res.json().then(function (body) {
      if (body.error) {
        throw new Error(body.error.errors[0].message);
      }

      var _body$data$translatio = _slicedToArray(body.data.translations, 1),
          translation = _body$data$translatio[0];

      if (!translation) {
        throw new Error('Translation not found');
      }

      return translation.translatedText;
    });
  }
};
var yandex = {
  needkey: true,
  fetch: function fetch(_ref2) {
    var key = _ref2.key,
        from = _ref2.from,
        to = _ref2.to,
        text = _ref2.text;
    return ["https://translate.yandex.net/api/v1.5/tr.json/translate?key=".concat(key, "&lang=").concat(from, "-").concat(to, "&text=").concat(encodeURIComponent(text)), {
      method: 'POST',
      body: ''
    }];
  },
  parse: function parse(res) {
    return res.json().then(function (body) {
      if (body.code !== 200) {
        throw new Error(body.message);
      }

      return body.text[0];
    });
  }
};
var _default = {
  google: google,
  yandex: yandex
};
exports.default = _default;