"use strict";

var _toConsumableArray = require("/Users/s114279/work/dev/own/appbuilder/mdb-react-admin-pro/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _engines = _interopRequireDefault(require("./engines"));

var _language = _interopRequireDefault(require("./language"));

var _cache = _interopRequireDefault(require("./cache"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // Translates text into different languages.
// Cache the translations to avoid re-sending requests.
// Main function


function Translate() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  /*
  if (!(this instanceof Translate)) {
    return new Translate(options);
  }
  */
  var defaults = {
    from: 'en',
    to: 'en',
    cache: undefined,
    language: _language.default,
    engines: _engines.default,
    engine: 'google',
    keys: {}
  };

  var translate = function translate(text) {
    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    // Load all of the appropriate options (verbose but fast)
    // Note: not all of those *should* be documented since some are internal only
    if (typeof opts === 'string') opts = {
      to: opts
    };
    opts.text = text;
    opts.from = (0, _language.default)(opts.from || translate.from);
    opts.to = (0, _language.default)(opts.to || translate.to);
    opts.cache = opts.cache || translate.cache;
    opts.engines = opts.engines || {};
    opts.engine = opts.engine || translate.engine;
    opts.id = opts.id || "".concat(opts.from, ":").concat(opts.to, ":").concat(opts.engine, ":").concat(opts.text);
    opts.keys = opts.keys || translate.keys || {};
    /* TODO: What is the point of this loop?
    for (const name of translate.keys) {
      opts.keys[name] = opts.keys[name];
    }
    */

    opts.key = opts.key || translate.key || opts.keys[opts.engine]; // TODO: validation for few of those
    // Use the desired engine

    var engine = opts.engines[opts.engine] || translate.engines[opts.engine]; // If it is cached return ASAP

    var cached = _cache.default.get(opts.id);

    if (cached) return Promise.resolve(cached); // Target is the same as origin, just return the string

    if (opts.to === opts.from) {
      return Promise.resolve(opts.text);
    } // Will load only for Node.js and use the native function
    // in browsers and in React Native.


    if (typeof fetch === 'undefined') {
      // eslint-disable-next-line global-require
      global.fetch = require('node-fetch');
    }

    if (engine.needkey && !opts.key) {
      throw new Error("The engine \"".concat(opts.engine, "\" needs a key, please provide it"));
    }

    var fetchOpts = engine.fetch(opts);
    return fetch.apply(void 0, _toConsumableArray(fetchOpts)).then(engine.parse).then(function (translated) {
      return _cache.default.put(opts.id, translated, opts.cache);
    });
  };

  for (var _i = 0, _Object$keys = Object.keys(defaults); _i < _Object$keys.length; _i++) {
    var key = _Object$keys[_i];
    translate[key] = typeof options[key] === 'undefined' ? defaults[key] : options[key];
  }

  return translate;
} // Small hack needed for Webpack/Babel: https://github.com/webpack/webpack/issues/706


var exp = new Translate();
exp.Translate = Translate;
var _default = exp;
exports.default = _default;