
import React, { Component } from "react";

import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBListGroup,
    MDBListGroupItem,
    MDBMedia,
    MDBIcon,
    MDBView,
    MDBBadge
  } from 'mdbreact';
  
import Form from "react-jsonschema-form";
import { getHandler } from "../../../uplift/actions";
import update from "immutability-helper";
const moment = require('moment-timezone');
const _ = require('lodash');

const schema = {
    title: "Thread",
    type: "object",
    required: [],
    properties: {
           name: {type: "string", title: "name", default: null},
   description: {type: "string", title: "description", default: null}
     }
  };

const log = (type) => console.log.bind(console, type);

export default class Screen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Thread:{},
        };
        /*
        this.state = {
             data: <#if body.data??>
             ${camelContext.getRegistry().lookupByName("INTERNAL_CONTEXT_OBJECTMAPPER").writeValueAsString(body.data.Thread_by_pk)}
             <#else>
             {} 
             </#if>
        };

        
        */
    }
 

    componentDidMount(){
        console.log(this.props.match);
        getHandler()([{ 
            type: 'HTTP4',  
            props: {
                onComplete:()=>{},
                noSpinner:false,
                method:'GET',
                path: `/dashboard/thread/upsert?id=${this.props.match.params.id}`,
                }
            }], this.props.parent, this); 
    }

    submit = (form) => {

        if(form.errors.length==0){
        getHandler()([
            { type: 'HTTP4',  props: { onStart1: ()=>{ this.setState({loading:true});}, 
            onComplete: ()=>{ this.setState({submitting:false});}, noSpinner:false, 
            method:'PUT', path: "/dashboard/thread/upsert"+`${_.get(this.props.match, "params.id")?"?id="+_.get(this.props.match, "params.id"):''}`,
            data: {form:  form.formData, } } }],this.props.parent);
        }
    }

    render() {
        return (
            <MDBContainer fluid>
    
            <section dlassName="mb-5">
                <Form schema={schema}
                formData={this.state.Thread}
                onChange={e=>{ this.setState(update(this.state,{Thread:{$set:e.formData}}))}}
                onSubmit={this.submit}
                onError={log("errors")} />
            </section>
            
      </MDBContainer>
        );
    }
}

