import React from 'react';
import { 
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBChip,
  MDBBadge,
} from 'mdbreact';
import LinkCard from '../LinkCard';

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // No avatars:
      show1: true,
      show2: true,
      show3: true
    }
  }

  handleCloseHere = param => e => {
    this.setState({
      ["show" + param]: false
    });
  };

  render() {

    const { show1, show2, show3} = this.state

    return (
        <MDBContainer fluid>
    
          <section>
              <h5 className="mt-5 mb-5 dark-grey-text font-weight-bold">Basic examples</h5>
              <MDBRow className="pb-4">
                  <MDBCol md="12" lg="4" className="mt-2 mb-4">
                    <MDBChip src="https://mdbootstrap.com/img/Photos/Avatars/avatar-6.jpg" alt="Contact Person" bgColor="pink lighten-4">
                      Jane Doe
                    </MDBChip>
                    <MDBChip src="https://mdbootstrap.com/img/Photos/Avatars/avatar-5.jpg" alt="Contact Person" bgColor="pink lighten-4">
                      Anna Black
                    </MDBChip>

                    { show1 &&  <MDBChip bgColor="pink lighten-4" close handleClose={this.handleCloseHere(1)}>
                      Tag 1
                    </MDBChip>}
                    { show2 &&  <MDBChip bgColor="pink lighten-4" close handleClose={this.handleCloseHere(2)}>
                      Tag 2
                    </MDBChip>}
                    { show3 &&  <MDBChip bgColor="pink lighten-4" close handleClose={this.handleCloseHere(3)}>
                      Tag 3
                    </MDBChip>}
                  </MDBCol>
    
                  <MDBCol md="8" lg="6" className="mb-4">
                      <h1>Example heading <MDBBadge color="red">New</MDBBadge></h1>
                      <h2>Example heading <MDBBadge color="blue">New</MDBBadge></h2>
                      <h3>Example heading <MDBBadge color="pink">New</MDBBadge></h3>
                      <h4>Example heading <MDBBadge color="green">New</MDBBadge></h4>
                      <h5>Example heading <MDBBadge color="grey">New</MDBBadge></h5>
                      <h6>Example heading <MDBBadge color="indigo">New</MDBBadge></h6>
                  </MDBCol>
    
                  <MDBCol md="4" lg="2" className="mb-4">
                      <MDBBadge color="default">Default</MDBBadge> <br/>
                      <MDBBadge color="primary">Primary</MDBBadge> <br/>
                      <MDBBadge color="success">Success</MDBBadge> <br/>
                      <MDBBadge color="info">Info</MDBBadge> <br/>
                      <MDBBadge color="warning">Warning</MDBBadge> <br/>
                      <MDBBadge color="danger">Danger</MDBBadge>
                  </MDBCol>
              </MDBRow>
          </section>

          <LinkCard docs="https://mdbootstrap.com/docs/react/components/badges/"/>
        </MDBContainer>
    );
  }
}

export default Tags;