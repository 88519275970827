import React from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBFormInline,
  MDBRow,
  MDBCol,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBMask,
  MDBIcon,
  MDBView,
  MDBBtn,
  MDBFooter,
} from 'mdbreact';
import {
  i18n,
} from 'web-translate';

import { getHandler } from '../../../../uplift/actions';

export default class Screen extends React.Component {

render(){ 
    const self = this.props.parent;
    return <MDBNavbar
color="primary-color"
dark
expand="md"
fixed="top"
scrolling
{...this.props.options}
style={{
    zIndex: 100,
  }}
>
<MDBContainer>
<MDBNavbarBrand>
<strong className="black-text1">Sesame Open</strong>
</MDBNavbarBrand>
<MDBNavbarToggler
onClick={self.toggleCollapse("navbarCollapse")}
className="black-text1"
/>
<MDBCollapse
id="navbarCollapse"
isOpen={self.state.collapseID}
navbar
>
<MDBNavbarNav left>
<MDBNavItem>
  <MDBNavLink to="/"><span className="black-text1">{i18n("Home")}</span></MDBNavLink>
</MDBNavItem>
<MDBNavItem>
  <MDBNavLink to="/projects"><span className="black-text1">{i18n("ShowCase")}</span></MDBNavLink>
</MDBNavItem>
<MDBNavItem>
  <MDBNavLink to="/onboard"><span className="black-text1">{i18n("Prototype")}</span></MDBNavLink>
</MDBNavItem>
<MDBNavItem>
  <MDBNavLink to="/pricing"><span className="black-text1">{i18n("Pricing")}</span></MDBNavLink>
</MDBNavItem>
<MDBNavItem>
  <MDBNavLink to="/faq"><span className="black-text1">{i18n("FAQs")}</span></MDBNavLink>
</MDBNavItem>
<MDBNavItem>
    <a className="nav-link" href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}?Subject=Enquiries`}  target="_top">
      <span  className="black-text1">{i18n("Contact")}</span>
    </a>
</MDBNavItem>
<MDBNavItem>
    <a className="nav-link" href="#" onClick={(e)=>{e.preventDefault();getHandler()([{type:"SET_LANGUAGE",props:{lan:"zh"}}])}}>
      <span  className="black-text1">{i18n("Chinese")}</span>
    </a>
</MDBNavItem>
<MDBNavItem>
    <a className="nav-link" href="#" onClick={(e)=>{e.preventDefault();getHandler()([{type:"SET_LANGUAGE",props:{lan:"en"}}])}}>
      <span  className="black-text1">{i18n("English")}</span>
    </a>
</MDBNavItem>

{/*<MDBNavItem>
  <MDBNavLink to="/pages/workspace/shopping">Services</MDBNavLink>
</MDBNavItem>
<MDBNavItem>
  <MDBNavLink to="#!">Gallery</MDBNavLink>
</MDBNavItem>
<MDBNavItem>
  <MDBNavLink to="#!">Contact</MDBNavLink>
</MDBNavItem>*/}
</MDBNavbarNav>
{/*<MDBNavbarNav right>
<MDBNavItem>
  <MDBFormInline waves>
    <div className="md-form my-0">
      <input
        className="form-control mr-sm-2"
        type="text"
        placeholder="Search"
        aria-label="Search"
      />
    </div>
  </MDBFormInline>
</MDBNavItem>
</MDBNavbarNav>*/}
</MDBCollapse>
</MDBContainer>
</MDBNavbar>}
}