import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import './assets/scss/mdb.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';

import { createBrowserHistory } from "history";
import * as Sentry from '@sentry/browser';
Sentry.init({dsn: "https://d0d68c019c5547c698c2ee9acb3cbbde@sentry.io/1804751"});
const customHistory = createBrowserHistory();
require("./uplift/utilities/log");

ReactDOM.render(<Router history={customHistory}><App /></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
